import ACTION from '../actionTypes';

export function startLoading() {
  return {
    type: ACTION.START_LOADING,
  };
}

export function finishLoading() {
  return {
    type: ACTION.FINISH_LOADING,
  };
}

export function finishAllLoading() {
  return {
    type: ACTION.FINISH_ALL_LOADING,
  };
}

export function startUploading(message, progress = 0) {
  return {
    type: ACTION.START_UPLOADING,
    uploadMessage: message || '',
    progress,
  };
}

export function finishUploading() {
  return {
    type: ACTION.FINISH_UPLOADING,
  };
}

export function setUploadingProgress(progress) {
  return {
    type: ACTION.SET_UPLOADING_PROGRESS,
    progress,
  };
}
