import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Button } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import Reorder, { reorder } from 'react-reorder';
import Dialog from '../../common/Dialog';
import ImageEditor from '../../common/ImageEditor';
import ExportDropdown from './ExportDropdown';
import { dataURLtoBlob, getImagesFromClipboard } from '../../../utils';
import * as ToastAction from '../../../store/actions/toast.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as CommonAction from '../../../store/actions/common.action';
import config from '../../../constants/config';

let mouseDownTimeStamp;

function getDraftPreview(draft) {
  return `${config.DRAFT_URL}/${draft._id}.${draft.type.split(/\W/)[1]}?q=${draft.updatedAt}`;
}

const CaptureModal = ({
  activeProject, open, onCancel, onUpload, createToast, startLoading, finishLoading,
}) => {
  const [capturedImages, setCapturedImages] = useState([]);
  const [editingImage, setEditingImage] = useState(null);
  const [capturingScreen, setCapturingScreen] = useState(null);
  const [countingSeconds, setCountingSeconds] = useState(0);

  const osInfo = useMemo(() => {
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      return {
        os: 'MacOS',
        captureKey: 'Ctrl+Win+Shift+4',
        pasteKey: 'Ctrl+V',
      };
    }
    if (navigator.appVersion.indexOf('Linux') !== -1) {
      return {
        os: 'Linux',
        captureKey: 'Ctrl+Shift+PrtScr',
        pasteKey: 'Ctrl+V',
      };
    }
    return {
      os: 'Windows OS',
      captureKey: 'PrtScr',
      pasteKey: 'Ctrl+V',
    };
  }, []);

  useEffect(() => {
    if (activeProject) {
      startLoading();
      PortfolioService.listDrafts(activeProject).then((result) => {
        finishLoading();
        if (!result.error) {
          result.drafts.forEach((image) => {
            image.preview = getDraftPreview(image);
          });
          setCapturedImages(result.drafts);
        }
      });
    }
  }, [activeProject, finishLoading, startLoading]);

  const onAddMedia = useCallback((image) => {
    setEditingImage({
      _id: null,
      file: image,
      preview: image.preview,
      title: '',
      description: '',
      project: activeProject._id,
    });
  });

  const onPasteImage = (e) => {
    const images = getImagesFromClipboard(e);
    if (images.length) {
      onAddMedia(images[0]);
    }
  };

  const onRemoveMedia = async (image) => {
    if (!window.confirm('Are you sure to remove this screenshot?')) {
      return;
    }

    startLoading();
    const result = await PortfolioService.deleteDraft(image._id);
    finishLoading();
    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Removing image has been failed.',
      });
    } else {
      setCapturedImages(capturedImages.filter((img) => img._id !== image._id));
      createToast({
        type: 'success',
        message: 'Image has been removed successfully.',
      });
    }
  };

  const onSaveImage = async (newImage) => {
    if (newImage._id) {
      startLoading();
      const result = await PortfolioService.updateDraft(newImage);
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: result.message || 'Saving image has been failed.',
        });
      } else {
        result.draft.preview = getDraftPreview(result.draft);
        setCapturedImages(capturedImages.map((img) => (img._id === newImage._id ? result.draft : img)));
        createToast({
          type: 'success',
          message: 'Image has been saved successfully.',
        });
        setEditingImage(null);
      }
    } else {
      startLoading();
      const result = await PortfolioService.uploadDraft(newImage);
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: result.message || 'Saving image has been failed.',
        });
      } else {
        result.draft.preview = getDraftPreview(result.draft);
        setCapturedImages([...capturedImages, result.draft]);
        createToast({
          type: 'success',
          message: 'Image has been saved successfully.',
        });
        setEditingImage(null);
      }
    }
  };

  const onMouseDown = () => {
    if (!mouseDownTimeStamp) {
      mouseDownTimeStamp = new Date().getTime();
    }
  };

  const onMouseUp = (image) => {
    if (mouseDownTimeStamp) {
      const delay = new Date().getTime() - mouseDownTimeStamp;
      if (delay < 200) {
        setEditingImage(image);
      }
      mouseDownTimeStamp = undefined;
    }
  };

  const onReorder = async (event, previousIndex, nextIndex) => {
    const newImages = reorder(capturedImages, previousIndex, nextIndex);
    newImages.forEach((image, index) => {
      image.order = index + 1;
    });
    setCapturedImages(newImages);
    await PortfolioService.reorderDrafts(newImages.map((image) => ({
      _id: image._id,
      order: image.order,
    })));
  };

  const onUploadImages = async () => {
    startLoading();
    const images = [];
    for (const image of capturedImages) {
      if (image.file) {
        images.push(image.file);
      } else {
        const blob = await fetch(image.preview).then((res) => res.blob());
        blob.preview = URL.createObjectURL(blob);
        images.push(blob);
      }
    }
    finishLoading();

    onUpload(images);
  };

  const onCancelCapture = useCallback(() => {
    if (!capturingScreen)
      return;

    const tracks = capturingScreen.srcObject.getTracks();
    tracks.forEach((track) => track.stop());

    capturingScreen.srcObject = null;
    capturingScreen.remove();

    setCapturingScreen(null);
    setCountingSeconds(0);
  }, [capturingScreen]);

  const captureScreen = useCallback(() => {
    if (!capturingScreen)
      return;

    try {
      const canvas = document.createElement('canvas');
      canvas.width = capturingScreen.videoWidth;
      canvas.height = capturingScreen.videoHeight;
      canvas.styles = 'display: none;';
      canvas.getContext('2d')
        .drawImage(capturingScreen, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL();
      const newImage = dataURLtoBlob(dataUrl);
      newImage.preview = URL.createObjectURL(newImage);
      onAddMedia(newImage);

      canvas.remove();
      onCancelCapture();
    } catch (e) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: e.message || 'Screenshot has been failed.',
      });
    }
  }, [capturingScreen, createToast, onAddMedia, onCancelCapture]);

  const discountSecond = useCallback(() => {
    if (countingSeconds === 0)
      return;

    setCountingSeconds(countingSeconds - 1);
    if (countingSeconds === 1) {
      setTimeout(captureScreen, 500);
    }
  }, [captureScreen, countingSeconds]);

  useEffect(() => {
    if (!countingSeconds || !capturingScreen)
      return;

    const timer = setTimeout(discountSecond, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [capturingScreen, countingSeconds, discountSecond]);

  const onAutoCapture = async () => {
    try {
      const videoEl = document.createElement('video');
      videoEl.autoplay = true;
      const constraints = {
        audio: false,
        video: {
          cursor: 'none',
        },
      };
      videoEl.srcObject = await navigator.mediaDevices.getDisplayMedia(constraints);
      setCapturingScreen(videoEl);
      setCountingSeconds(3);
    } catch (e) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: e.message || 'Screenshot has been failed.',
      });
    }
  };

  const onClearAll = async (confirm = true) => {
    if (confirm && !window.confirm('Are you sure to remove all captured images?'))
      return;

    startLoading();
    const result = await PortfolioService.clearDrafts(activeProject);
    finishLoading();
    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Clearing drafts has been failed.',
      });
    } else {
      setCapturedImages([]);
      createToast({
        type: 'success',
        message: 'Images have been removed successfully.',
      });
    }
  };

  return (
    <>
      <Dialog
        title="Captured Screenshots"
        className="capture-modal"
        open={open && !editingImage}
        maxWidth="md"
        onClose={onCancel}
        onPaste={onPasteImage}
        footerActions={(
          <>
            {Boolean(capturedImages.length) && (
              <Button className="btn-danger size-sm px-3 mr-2" onClick={() => onClearAll(true)}>Clear All</Button>
            )}

            <Button className="size-sm px-3 mr-2" onClick={onCancel}>Cancel</Button>

            <ExportDropdown project={activeProject} disabled={!capturedImages.length} onClearAll={onClearAll} />

            <Button className="btn-primary size-sm px-3 ml-2" disabled={!capturedImages.length} onClick={onUploadImages}>
              {`Upload Images${capturedImages.length ? ` (${capturedImages.length})` : ''}`}
            </Button>
          </>
        )}
      >
        <div className="text-right">
          <Button className="btn-warning size-sm px-3 mr-2" onClick={onAutoCapture}>
            <CameraAlt className="mr-1" />
            Auto Capture
          </Button>
        </div>

        {
          capturedImages.length
            ? (
              <Reorder
                className="images"
                reorderId="captured-images"
                holdTime={200}
                onReorder={onReorder}
              >
                {
                  capturedImages.map((image) => (
                    <div key={image._id} className="captured-image">
                      <div className="image" onMouseDown={onMouseDown} onMouseUp={() => onMouseUp(image)}>
                        <img
                          className="pointer-event-none"
                          src={image.preview}
                          alt={image.preview}
                        />
                      </div>
                      <i className="fa fa-times-circle remove-button" onClick={() => onRemoveMedia(image)} />
                      <div className="title">{image.title}</div>
                    </div>
                  ))
                }
              </Reorder>
            )
            : (
              <div className="message">
                {`Press ${osInfo.captureKey} to capture screenshot.`}
                <br />
                {`Press ${osInfo.pasteKey} to paste captured image here.`}
              </div>
            )
        }

        {
          !!countingSeconds && (
            <div className="counting-seconds text-center">
              <div className="seconds-text">{countingSeconds}</div>
              <div className="cancel-btn cursor-pointer" onClick={onCancelCapture}>Cancel</div>
            </div>
          )
        }
      </Dialog>

      {Boolean(editingImage) && (
        <ImageEditor image={editingImage} onCancel={() => setEditingImage(null)} onSave={onSaveImage} />
      )}
    </>
  );
};

CaptureModal.propTypes = {
  activeProject: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpload: PropTypes.func,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

CaptureModal.defaultProps = {
  open: false,
  onCancel: () => {},
  onUpload: () => {},
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CaptureModal);
