import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

import config from '../../../constants/config';

const RecentImage = ({ image }) => (
  <div className="recent-image">
    <Link to={`/portfolio/${image._id}?from=recent`}>
      <div className="image">
        {
          image.type.startsWith('image')
            ? <img src={`${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`} alt={image._id} />
            : (
              <div className="video-frame">
                <img src="resources/images/video/video_frame.png" alt={image._id} className="video-frame-img" />
                <video className="mw-100 mh-100" src={`${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`} />
              </div>
            )

        }
        <span className="back-drop section-name">{`${image.project.name} / ${image.section}`}</span>
      </div>
      <div className="sub-section">
        {image.subSection}
      </div>
    </Link>
  </div>
);

RecentImage.propTypes = {
  image: PropTypes.object.isRequired,
};

export default RecentImage;
