import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import TuiImageEditor from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import { Button, Grid } from '@material-ui/core';
import FormControl from '../FormControl';
import { patchFormData } from '../FormControl/utils';
import { dataURLtoBlob } from '../../../utils';

const whiteTheme = {
  'common.backgroundColor': 'white',
  'common.border': '1px solid #444',
  'submenu.backgroundColor': '#fffe',
  'submenu.partition.color': '#aaa',
  'submenu.normalLabel.color': '#444',
  'submenu.activeLabel.color': '#444',
  'checkbox.border': '1px solid #444',
  'range.title.color': '#444',
  'range.pointer.color': '#666',
  'range.bar.color': '#888',
  'range.subbar.color': '#444',
  'range.value.color': 'black',
  'range.value.border': '1px solid #444',
  'range.value.backgroundColor': 'white',
};

const ImageEditor = ({
  image, onCancel, onSave,
}) => {
  const form = useMemo(() => ({
    title: {
      name: 'title',
      label: 'Title',
      placeholder: 'Title',
    },
    description: {
      name: 'description',
      type: 'summer-note',
      label: 'Description',
      controlOptions: {
        height: 120,
      },
    },
  }), []);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const imageEditor = useRef(null);

  useEffect(() => {
    setTitle(image.title || '');
    setDescription(image.description || '');

    patchFormData(form, {
      title: image.title || '',
      description: image.description || '',
    });
  }, [form, image]);

  useEffect(() => {
    const textSizeEl = document.querySelector('.image-editor-modal .tui-image-editor-menu-text .tie-text-range-value');
    if (textSizeEl) {
      textSizeEl.value = 18;
      textSizeEl.dispatchEvent(new KeyboardEvent('keyup', {
        code: 'Enter',
        key: 'Enter',
      }));
    }
  }, [imageEditor]);

  const onSaveImage = () => {
    const dataUrl = imageEditor.current.getInstance().toDataURL();
    const blob = dataURLtoBlob(dataUrl);
    blob.preview = URL.createObjectURL(blob);

    onSave({
      ...image,
      file: blob,
      title,
      description,
      type: blob.type,
      dataUrl,
    });
  };

  return (
    <div className="image-editor-modal">
      <TuiImageEditor
        ref={imageEditor}
        includeUI={{
          loadImage: {
            path: image.preview,
            name: 'Captured Image',
          },
          theme: whiteTheme,
          uiSize: {
            width: '100%',
            height: '100%',
          },
          initMenu: 'text',
          menuBarPosition: 'right',
        }}
        usageStatistics={false}
      />

      <div className="editor-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <FormControl control={form.title} size="sm" className="mb-0" onChange={(_, value) => setTitle(value)} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <FormControl control={form.description} size="sm" className="mb-0" onChange={(_, value) => setDescription(value)} />
          </Grid>
        </Grid>
      </div>

      <div className="editor-actions d-flex">
        <Button className="size-sm px-3 mr-2" onClick={onCancel}>Cancel</Button>
        <Button className="btn-primary size-sm px-3" onClick={onSaveImage}>Save</Button>
      </div>
    </div>
  );
};

ImageEditor.propTypes = {
  image: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

ImageEditor.defaultProps = {
  onCancel: null,
  onSave: null,
};

export default ImageEditor;
