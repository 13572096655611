import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@material-ui/core';
import { Clear, KeyboardArrowRight, Search } from '@material-ui/icons';
import FormControl from '../../common/FormControl';
import { patchFormData, setFormControlOptions } from '../../common/FormControl/utils';

const SearchBox = ({
  clients, filter, onFilterChange,
}) => {
  const form = useMemo(() => ({
    client: {
      name: 'client',
      type: 'autocomplete',
      placeholder: 'Client',
      options: [],
      labelField: 'name',
    },
    projects: {
      name: 'projects',
      type: 'autocomplete',
      placeholder: 'Project',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
      },
      labelField: 'name',
    },
    industries: {
      name: 'industries',
      type: 'autocomplete',
      placeholder: 'Company Type / Industry',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
      },
    },
    locations: {
      name: 'locations',
      type: 'autocomplete',
      placeholder: 'Location',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
      },
    },
    search: {
      name: 'search',
      placeholder: 'Search',
    },
  }), []);

  useEffect(() => {
    patchFormData(form, filter);
  }, [filter, form]);

  useEffect(() => {
    let industries = [];
    let locations = [];
    clients.forEach((client) => {
      if (!client.projects) return;
      client.projects.forEach((project) => {
        if (project.caseStudy) {
          if (project.caseStudy.industry) {
            industries.push(project.caseStudy.industry);
          }
          if (project.caseStudy.location) {
            locations.push(project.caseStudy.location);
          }
        }
      });
    });
    industries = [...new Set(industries)].sort();
    locations = [...new Set(locations)].sort();

    setFormControlOptions(form.client, {
      options: clients,
    });
    setFormControlOptions(form.industries, {
      options: industries,
    });
    setFormControlOptions(form.locations, {
      options: locations,
    });
  }, [clients, form.client, form.industries, form.locations]);

  useEffect(() => {
    let projects = [];
    clients.forEach((client) => {
      if (!filter.client || client._id === filter.client._id) {
        projects = projects.concat(client.projects);
      }
    });
    projects = projects.sort((a, b) => a.name.localeCompare(b.name));
    setFormControlOptions(form.projects, {
      options: projects,
    });
  }, [clients, filter.client, form.projects]);

  const isFilterSet = useMemo(() => (
    filter.client || filter.projects.length || filter.industries.length || filter.locations.length || filter.search
  ), [filter]);

  const onInputChange = (field, value) => {
    const newFilter = {
      ...filter,
      [field]: value,
    };
    if (field === 'client' && value) {
      newFilter.projects = [];
    }
    onFilterChange(newFilter);
  };

  const onResetFilter = (e) => {
    e.stopPropagation();

    onFilterChange({
      search: '',
      client: null,
      projects: [],
      industries: [],
      locations: [],
    });
  };

  return (
    <Accordion className="search-box">
      <AccordionSummary
        expandIcon={<KeyboardArrowRight />}
      >
        <div className="d-flex align-items-center">
          <h6 className="text-uppercase">Filter</h6>
          <Button
            className="size-sm ml-3"
            disabled={!isFilterSet}
            onClick={onResetFilter}
            onFocus={(e) => e.stopPropagation()}
          >
            <Clear className="text-sm mr-2" />
            Reset all
          </Button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                control={form.search}
                size="sm"
                className="mb-0"
                startAdornment={<Search className="text-muted text-xl pointer-event-none" />}
                onChange={onInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl control={form.client} size="sm" className="mb-0" onChange={onInputChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl control={form.projects} size="sm" className="mb-0" onChange={onInputChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl control={form.industries} size="sm" className="mb-0" onChange={onInputChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl control={form.locations} size="sm" className="mb-0" onChange={onInputChange} />
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

SearchBox.propTypes = {
  clients: PropTypes.array,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  clients: [],
};

export default SearchBox;
