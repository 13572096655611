import React, {
  useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';
import config from '../../constants/config';

import * as ToastAction from '../../store/actions/toast.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as CommonAction from '../../store/actions/common.action';

const SharePage = ({
  match, createToast, startLoading, finishLoading,
}) => {
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState(undefined);
  const [imageElement, setImageElement] = useState(null);

  const url = useMemo(() => {
    if (!image) return '';
    if (image.type.startsWith('image')) {
      return `${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`;
    }
    return `${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`;
  }, [image]);

  useEffect(() => {
    const imageId = match.params.id;
    startLoading();
    PortfolioService.getImage(imageId).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message,
          type: 'error',
        });
        setImage(null);
      } else {
        setImage(result.image);
      }
    });
  }, [createToast, finishLoading, match.params.id, startLoading]);

  const onImageLoad = () => {
    setShowImage(true);
    if (imageElement) {
      let { width, height } = imageElement;
      const maxWidth = imageElement.parentNode.clientWidth;
      const maxHeight = imageElement.parentNode.clientHeight;
      if (width > maxWidth) {
        height = (height / width) * maxWidth;
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = (width / height) * maxHeight;
        height = maxHeight;
      }
      imageElement.width = width;
    }
  };

  const onZoom = (ratio) => {
    if (imageElement) {
      if (ratio < 1 && (imageElement.width < 150 || imageElement.height < 150)) {
        return;
      }

      imageElement.width *= ratio;
      if (imageElement.width > window.innerWidth) {
        imageElement.parentNode.style.justifyContent = 'flex-start';
      } else {
        imageElement.parentNode.style.justifyContent = 'center';
      }
      if (imageElement.height > window.innerHeight) {
        imageElement.parentNode.style.alignItems = 'flex-start';
      } else {
        imageElement.parentNode.style.alignItems = 'center';
      }
      imageElement.parentNode.style.overflow = 'auto';
    }
  };

  return (
    <div className="share-page">
      <div className="header">
        <div className="logo-wrapper">
          <img src="/resources/images/protovate.png" alt="PROTOVATE" />
        </div>
        <div className="list-wrapper">
          <img src="/resources/images/share/phone.png" alt="Phone" />
          <a href="tel:18442020124" target="_blank" rel="noreferrer">1 844 202 0124</a>
          <img src="/resources/images/share/earth.png" alt="Website" />
          <a href="http://protovate.com" target="_blank" rel="noreferrer">protovate.com</a>
        </div>
      </div>
      <div className="content">
        {
          image
            ? (
              <>
                <div className="preview">
                  {
                    image.type.startsWith('image')
                      ? <img className={`media ${!showImage ? 'hidden' : ''}`} src={url} alt={image.subSection} ref={setImageElement} onLoad={onImageLoad} />
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      : <video className="media" controls src={url} />
                  }
                </div>
                {
                  imageElement
                    ? (
                      <div className="action-buttons">
                        <i className="fa fa-search-minus action-button" data-toggle="tooltip" title="Zoom Out" onClick={() => onZoom(0.6667)} />
                        <i className="fa fa-search-plus action-button" data-toggle="tooltip" title="Zoom In" onClick={() => onZoom(1.5)} />
                      </div>
                    )
                    : null
                }
              </>
            )
            : (
              <div className="message h-100 d-flex-center">
                {
                  image === undefined
                    ? 'Loading...'
                    : 'No preview available.'
                }
              </div>
            )
        }
      </div>
      <div className="footer">
        <p>innovative technology for smart companies</p>
      </div>
    </div>
  );
};

SharePage.propTypes = {
  match: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(SharePage);
