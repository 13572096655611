import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import moment from 'moment-timezone';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import EditLiveLinkModal from '../../EditLiveLinkModal';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import FormControl from '../../../common/FormControl';
import { patchFormControl, setFormControlOptions } from '../../../common/FormControl/utils';
import { ROLES } from '../../../../constants';

const LiveLinkTab = ({
  user, activeProject, removeLiveLink, startLoading, finishLoading, createToast,
}) => {
  const form = useMemo(() => ({
    name: {
      name: 'name',
      type: 'autocomplete',
      placeholder: 'Select live link',
      options: [],
      labelField: 'name',
      controlOptions: {
        disableClearable: true,
      },
    },
  }), []);

  const [liveLinks, setLiveLinks] = useState([]);
  const [activeLiveLink, setActiveLiveLink] = useState(null);
  const [editingLiveLink, setEditingLiveLink] = useState(null);

  useEffect(() => {
    if (!activeProject) {
      return;
    }

    const links = activeProject.liveLinks || [];
    const defaultLinkNames = ['Google Play Store', 'Apple Store', 'Front End Website', 'Back End Website'];
    const sortedLinks = links.sort((a, b) => {
      let id1 = defaultLinkNames.indexOf(a.name);
      let id2 = defaultLinkNames.indexOf(b.name);
      if (id1 === -1) id1 = 10000;
      if (id2 === -1) id2 = 10000;
      return id1 - id2;
    });

    setLiveLinks(sortedLinks);
  }, [activeProject]);

  useEffect(() => {
    if (!activeLiveLink || activeLiveLink.project !== activeProject._id) {
      setActiveLiveLink(liveLinks[0]);
    }
    setEditingLiveLink(null);
  }, [activeLiveLink, activeProject, liveLinks]);

  useEffect(() => {
    setFormControlOptions(form.name, { options: liveLinks });
  }, [form.name, liveLinks]);

  useEffect(() => {
    patchFormControl(form.name, activeLiveLink);
  }, [form.name, activeLiveLink]);

  const onSelectLiveLink = (_field, link) => {
    setActiveLiveLink(link);
  };

  const onSaveLiveLink = (link) => {
    setActiveLiveLink(link);
    setEditingLiveLink(null);
  };

  const onRemoveLiveLink = async () => {
    if (!window.confirm('Are you sure to remove this live link?')) {
      return;
    }

    startLoading();
    const result = await PortfolioService.removeLiveLink(activeLiveLink);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || 'Removing live link has been failed.',
        type: 'error',
      });
    } else {
      removeLiveLink(activeLiveLink);
      setActiveLiveLink(null);
      createToast({
        message: 'Live link has been removed successfully.',
        type: 'success',
      });
    }
  };

  if (!activeProject) {
    return null;
  }

  return (
    <>
      {activeLiveLink ? (
        <>
          <div className="tab-header d-flex justify-content-between align-items-center">
            <FormControl
              control={form.name}
              size="sm"
              className="mb-0"
              fullWidth={false}
              onChange={onSelectLiveLink}
            />
            {user.role !== ROLES.CLIENT && (
              <Button
                className="btn-primary size-sm px-4"
                onClick={() => setEditingLiveLink({ _id: null })}
              >
                Add Link
              </Button>
            )}
          </div>

          <div className="link-preview p-2">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h3 className="font-weight-normal text-ellipsis mr-4">
                {activeLiveLink.name}
                &nbsp;Link for&nbsp;
                <b>{activeProject.name}</b>
              </h3>

              {user.role !== ROLES.CLIENT && (
                <div className="d-flex align-items-center">
                  <Tooltip title="Edit" placement="top" arrow>
                    <IconButton
                      className="btn-outline-secondary size-sm ml-2"
                      onClick={() => setEditingLiveLink(activeLiveLink)}
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove" placement="top" arrow>
                    <IconButton
                      className="btn-outline-secondary size-sm ml-2"
                      onClick={onRemoveLiveLink}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>

            <div className="link-info">
              <span className="title">{`${activeLiveLink.name} Link:`}</span>
              <a
                className="text-primary hover-underline"
                href={/^\w+:\/\//.test(activeLiveLink.link) ? activeLiveLink.link : `http://${activeLiveLink.link}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {activeLiveLink.link}
              </a>
            </div>
            <div className="link-info">
              <span className="title">Demo User Name:</span>
              <span>{activeLiveLink.userName}</span>
            </div>
            <div className="link-info">
              <span className="title">Demo Password:</span>
              <span>{activeLiveLink.password}</span>
            </div>
            <div className="link-info">
              <span className="title">Last Verified Date:</span>
              <span>
                {
                  activeLiveLink.lastVerified
                    ? moment(activeLiveLink.lastVerified).format('lll')
                    : null
                }
              </span>
            </div>
            <div className="link-info d-flex">
              <span className="title">Demo Notes:</span>
              <div
                className="demo-note flex-grow-1 overflow-auto"
                dangerouslySetInnerHTML={{ __html: activeLiveLink.notes }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="text-center py-4 my-5">
          {user.role !== ROLES.CLIENT && (
            <Button
              className="btn-primary size-sm px-4 mb-4"
              onClick={() => setEditingLiveLink({ _id: null })}
            >
              Add Link
            </Button>
          )}
          <p className="no-data p-0 m-0">
            No links have been created for this project yet.
          </p>
        </div>
      )}

      {editingLiveLink && (
        <EditLiveLinkModal
          open
          project={activeProject}
          liveLink={editingLiveLink}
          onSave={onSaveLiveLink}
          onClose={() => setEditingLiveLink(null)}
        />
      )}
    </>
  );
};

LiveLinkTab.propTypes = {
  user: PropTypes.object.isRequired,
  activeProject: PropTypes.object,
  removeLiveLink: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

LiveLinkTab.defaultProps = {
  activeProject: null,
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeLiveLink: PortfolioAction.removeLiveLink,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveLinkTab);
