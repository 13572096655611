import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Button, Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import config from '../../../../constants/config';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';

const ExportDropdown = ({
  project, disabled, onClearAll, startLoading, finishLoading, createToast,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onExportToPDF = async () => {
    startLoading();
    const result = await PortfolioService.exportReport(project);
    finishLoading();
    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Exporting report has been failed.',
      });
    } else {
      const filename = `${project.name}-${new Date().toUTCString()}.pdf`;
      PortfolioService.download(`${config.API_SERVER}/export/download-file/${result.file}/${encodeURIComponent(filename)}`, filename);
      await onClearAll(false);
    }
  };

  const onShare = async () => {
    startLoading();
    const result = await PortfolioService.exportReport(project);
    finishLoading();
    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Exporting report has been failed.',
      });
      return;
    }

    const link = `${window.location.host}/shared-report/${result.fileName}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link).then(() => {
        createToast({
          type: 'success',
          message: 'Copied shareable link.',
        });
      }).catch(() => {
        createToast({
          type: 'error',
          message: 'Copy shareable link has been failed.',
        });
      });
    } else {
      const copyToClipboard = (e) => {
        e.preventDefault();
        e.clipboardData.setData('text/plain', link);
        document.removeEventListener('copy', copyToClipboard);
        createToast({
          type: 'success',
          message: 'Copied shareable link.',
        });
      };

      document.addEventListener('copy', copyToClipboard);
      document.execCommand('copy');
    }
  };

  return (
    <div className="mr-2">
      <Button className="btn-primary size-sm px-3" disabled={disabled} onClick={handleClick}>
        Report
        <KeyboardArrowDown fontSize="small" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={onExportToPDF}>Export PDF</MenuItem>
        <MenuItem onClick={onShare}>Share Link</MenuItem>
      </Menu>
    </div>
  );
};

ExportDropdown.propTypes = {
  project: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClearAll: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(ExportDropdown);
