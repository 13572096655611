export const options = {
  width: '100%',
  height: 300,
  dialogsInBody: true,
  toolbar: [
    [
      'font',
      [
        'bold',
        'italic',
        'underline',
        'clear',
        'strikethrough',
        'superscript',
        'subscript',
        'fontsize',
      ],
    ],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['font', ['height']],
  ],
};
