import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import Dropzone from 'react-dropzone';
import { Button, Grid } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import { convertSvgFiles, getImagesFromClipboard } from '../../../utils';
import { MEDIA_STATUSES } from '../../../constants';
import Validators from '../../common/FormControl/validators';
import { patchFormData, setFormControlOptions, validateFormGroup } from '../../common/FormControl/utils';

const UploadModal = ({
  open, capturedImages, activeProject, client, onClose, setUploadingInfo, setActiveProject,
}) => {
  const form = useMemo(() => ({
    project: {
      name: 'project',
      type: 'autocomplete',
      label: 'Select Project *',
      placeholder: 'Select project',
      options: [],
      labelField: 'name',
      controlOptions: {
        disableClearable: true,
      },
      validators: [Validators.required()],
    },
    section: {
      name: 'section',
      type: 'autocomplete',
      label: 'Section *',
      placeholder: 'Section name',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
      validators: [Validators.required()],
    },
    subSection: {
      name: 'subSection',
      type: 'autocomplete',
      label: 'Sub Section',
      placeholder: 'Sub section name (optional)',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
    },
    status: {
      name: 'status',
      type: 'autocomplete',
      label: 'Status *',
      placeholder: 'Select status',
      options: MEDIA_STATUSES,
      validators: [Validators.required()],
    },
    version: {
      name: 'version',
      label: 'Version *',
      placeholder: 'Version',
      validators: [Validators.required()],
    },
  }), []);

  const [uploadInfo, setUploadInfo] = useState({
    project: activeProject,
    section: '',
    subSection: '',
    status: '',
    version: '',
    files: capturedImages,
  });

  const sectionNames = useMemo(() => {
    if (!uploadInfo.project)
      return [];

    let sections = uploadInfo.project.images.map((media) => media.section);
    sections = [...new Set(sections)];
    return sections;
  }, [uploadInfo.project]);

  const subSectionNames = useMemo(() => {
    if (!uploadInfo.project || !uploadInfo.section)
      return [];

    let subSections = [];
    uploadInfo.project.images.forEach((media) => {
      if (media.section === uploadInfo.section && media.subSection)
        subSections.push(media.subSection);
    });
    subSections = [...new Set(subSections)];
    return subSections;
  }, [uploadInfo.project, uploadInfo.section]);

  const sectionIds = useMemo(() => {
    if (!uploadInfo.project)
      return {};

    const sections = {};
    uploadInfo.project.images.forEach((media) => {
      if (!sections[media.section]) {
        sections[media.section] = media.version.split('.')[1] || 1;
      }
    });
    return sections;
  }, [uploadInfo.project]);

  const subSectionIds = useMemo(() => {
    if (!uploadInfo.project)
      return {};

    const subSections = {};
    uploadInfo.project.images.forEach((media) => {
      if (media.section === uploadInfo.section && media.subSection && !subSections[media.subSection]) {
        subSections[media.subSection] = media.version.split('.')[2] || 1;
      }
    });
    return subSections;
  }, [uploadInfo.project, uploadInfo.section]);

  useEffect(() => {
    if (!uploadInfo.project || !uploadInfo.section)
      return;

    const mediaIds = uploadInfo.project.images
      .filter((media) => media.section === uploadInfo.section && (!uploadInfo.subSection || media.subSection === uploadInfo.subSection))
      .map((media) => media.version.split('.').reverse()[0]);

    const projectId = uploadInfo.project.versionId;
    const sectionId = sectionIds[uploadInfo.section] || (Math.max(0, ...Object.values(sectionIds)) + 1);
    const subSectionId = subSectionIds[uploadInfo.subSection] || (Math.max(0, ...Object.values(subSectionIds)) + 1);
    const mediaId = Math.max(0, ...mediaIds) + 1;
    const version = `${projectId}.${sectionId}.${uploadInfo.subSection ? `${subSectionId}.` : ''}${mediaId}`;
    if (uploadInfo.version !== version) {
      setUploadInfo({
        ...uploadInfo,
        version,
      });
    }
  }, [sectionIds, subSectionIds, uploadInfo.project, uploadInfo.section, uploadInfo.subSection]);

  useEffect(() => {
    if (client) {
      setFormControlOptions(form.project, {
        options: client.projects,
      });
    }
    setFormControlOptions(form.section, {
      options: sectionNames,
    });
    setFormControlOptions(form.subSection, {
      options: subSectionNames,
    });
  }, [client, form.project, form.section, form.subSection, sectionNames, subSectionNames]);

  useEffect(() => {
    patchFormData(form, uploadInfo);
  }, [form, uploadInfo]);

  const onInputChange = (field, value) => {
    setUploadInfo({
      ...uploadInfo,
      [field]: value,
    });
  };

  const onPasteImage = (e) => {
    const images = getImagesFromClipboard(e);
    if (images.length) {
      onInputChange('files', images);
    }
  };

  const onFinish = async () => {
    if (!validateFormGroup(form))
      return;

    if (activeProject._id !== uploadInfo.project._id) {
      setActiveProject(uploadInfo.project);
    }

    const files = await convertSvgFiles(uploadInfo.files);
    setUploadingInfo({
      project: uploadInfo.project._id,
      section: uploadInfo.section,
      subSection: uploadInfo.subSection,
      status: uploadInfo.status,
      version: uploadInfo.version,
      files,
      index: 0,
    });

    if (capturedImages && capturedImages.length)
      await PortfolioService.clearDrafts(activeProject);

    onClose();
  };

  return (
    <Dialog
      title="Upload Media"
      className="upload-modal"
      open={open}
      onClose={onClose}
      onPaste={onPasteImage}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" disabled={!uploadInfo.files.length} onClick={onFinish}>Next</Button>
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl control={form.project} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.section} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.subSection} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.status} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.version} size="sm" className="mb-0" onChange={onInputChange} />
        </Grid>
      </Grid>
      <div className="mt-3">
        <label className="text-gray text-sm mb-2">Select one or more files to upload *</label>
        <Dropzone
          name="file"
          className="dropzone"
          accept={['image/*', 'video/*', 'audio/*']}
          onDrop={(files) => onInputChange('files', files)}
        >
          {
            uploadInfo.files.length > 0
              ? (
                <div className="preview">
                  {
                    uploadInfo.files[0].type.startsWith('image')
                      ? <img className="image" alt="Preview" src={uploadInfo.files[0].preview} />
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      : <video className="image" src={uploadInfo.files[0].preview} />
                  }

                  <span className="file-info">
                    {`${uploadInfo.files.length} files`}
                  </span>
                  <div className="backdrop">
                    {
                      uploadInfo.files.map((file, index) => <p key={index}>{file.name}</p>)
                    }
                  </div>
                </div>
              )
              : (
                <div className="dropify-message">
                  <i className="fa fa-file-image-o" />
                  <p>Drag and drop images or videos here or click</p>
                </div>
              )
          }
        </Dropzone>
      </div>
    </Dialog>
  );
};

UploadModal.propTypes = {
  open: PropTypes.bool,
  activeProject: PropTypes.object.isRequired,
  capturedImages: PropTypes.array,
  client: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setUploadingInfo: PropTypes.func.isRequired,
  setActiveProject: PropTypes.func.isRequired,
};

UploadModal.defaultProps = {
  open: false,
  capturedImages: [],
};

const mapStateToProps = (store) => ({
  client: store.portfolioReducer.client,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUploadingInfo: PortfolioAction.setUploadingInfo,
      setActiveProject: PortfolioAction.setActiveProject,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);
