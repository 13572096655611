import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import Progress from 'react-progressbar';

const UploadingNow = ({ loading, progress, uploadMessage }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className="spinner">
      <div className="uploading-spinner-wrapper">
        <div className="text-center">{uploadMessage}</div>
        <Progress completed={progress} className="uploading-progress" />
        <div className="text-center">
          {`${progress} %`}
        </div>
      </div>
    </div>
  );
};

UploadingNow.propTypes = {
  loading: PropTypes.bool,
  progress: PropTypes.number,
  uploadMessage: PropTypes.string,
};

UploadingNow.defaultProps = {
  loading: false,
  progress: 0,
  uploadMessage: '',
};

export default UploadingNow;
