import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ScaleLoader from 'react-spinners/ScaleLoader';
import ROUTES from '../constants/routes.json';
import * as PortfolioAction from '../store/actions/portfolio.action';
import * as AuthService from '../services/auth.service';
import * as AuthAction from '../store/actions/auth.action';
import * as CommonAction from '../store/actions/common.action';

const PrivateRoute = ({
  layout: L, component: C, user, props: cProps, path, setCurrentPage, signIn, startLoading, finishLoading, ...rest
}) => {
  const [activated, setActivated] = useState(undefined);

  useEffect(() => {
    if (user) {
      setActivated(true);
      return;
    }
    startLoading();
    AuthService.reloadUserInfo().then((user) => {
      finishLoading();
      if (user) {
        signIn(user);
        setActivated(true);
      } else {
        setActivated(false);
      }
    });
  }, [finishLoading, setActivated, signIn, startLoading, user]);

  useEffect(() => {
    setCurrentPage(path);
  }, [path, setCurrentPage]);

  if (activated === false) {
    return (
      <Redirect to={ROUTES.SIGN_IN.url} />
    );
  }

  if (!activated) {
    return (
      <div className="spinner">
        <ScaleLoader loading color="#1c3943" />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <L>
          <C {...props} {...cProps} match={rest.computedMatch} />
        </L>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  layout: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  user: PropTypes.object,
  path: PropTypes.string,
  props: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  user: null,
  path: '',
};

const mapStateToProps = (store) => ({
  user: store.authReducer.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setCurrentPage: PortfolioAction.setCurrentPage,
    signIn: AuthAction.signIn,
    startLoading: CommonAction.startLoading,
    finishLoading: CommonAction.finishLoading,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
