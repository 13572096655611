import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import moment from 'moment';
import { Button } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import Validators from '../../common/FormControl/validators';
import { patchFormData, validateFormControl, validateFormGroup } from '../../common/FormControl/utils';

const EditLiveLinkModal = ({
  open, liveLink: editingLiveLink, project, onSave, onClose,
  addLiveLink, editLiveLink, startLoading, finishLoading, createToast,
}) => {
  const form = useMemo(() => ({
    name: {
      name: 'name',
      type: 'autocomplete',
      label: 'Select Link For *',
      placeholder: 'Select link for',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
      validators: [Validators.required()],
    },
    link: {
      name: 'link',
      label: 'Live Link URL *',
      placeholder: 'Live link URL',
      validators: [Validators.required(), Validators.url()],
    },
    lastVerified: {
      name: 'lastVerified',
      label: 'Last Verified Date',
      placeholder: 'Last verified date',
      validators: [
        Validators.pattern(/^(0\d|1[0-2])\/([0-2]\d|3[01])\/(\d{4}) ([01]\d|2[0-3]):([0-5]\d)$/),
        Validators.pastTime(null, 'cannot be future date.'),
      ],
    },
    userName: {
      name: 'userName',
      label: 'Demo User Name',
      placeholder: 'Demo user name',
    },
    password: {
      name: 'password',
      label: 'Demo Password',
      placeholder: 'Demo password',
    },
    notes: {
      name: 'notes',
      type: 'summer-note',
      label: 'Demo Notes',
    },
  }), []);

  const [liveLink, setLiveLink] = useState({});
  const [notes, setNotes] = useState('');

  useEffect(() => {
    let lastVerified = '';
    if (editingLiveLink.lastVerified) {
      lastVerified = moment(editingLiveLink.lastVerified).format('MM/DD/YYYY HH:mm');
    } else if (!editingLiveLink._id) {
      lastVerified = moment().format('MM/DD/YYYY HH:mm');
    }

    setLiveLink({
      _id: editingLiveLink._id,
      name: editingLiveLink.name || '',
      link: editingLiveLink.link || '',
      userName: editingLiveLink.userName || '',
      password: editingLiveLink.password || '',
      lastVerified,
      notes: editingLiveLink.notes || '',
    });
    setNotes(editingLiveLink.notes || '');

    form.name.options = ['Google Play Store', 'Apple Store', 'Front End Website', 'Back End Website']
      .filter((s) => !project.liveLinks.find((l) => l.name === s));
  }, [editingLiveLink, form.name, project]);

  useEffect(() => {
    patchFormData(form, liveLink);
  }, [form, liveLink]);

  useEffect(() => {
    const nameValidator = ({ value }) => {
      if (!value || value.toLowerCase() === (editingLiveLink.name || '').toLowerCase())
        return null;

      const link = project.liveLinks.find((link) => link.name.toLowerCase() === value.toLowerCase());
      if (link)
        return `Live link already exists - ${link.name}`;
      return null;
    };

    form.name.validators = [
      Validators.required(),
      nameValidator,
    ];

    if (form.name.value)
      validateFormControl(form.name);
  }, [editingLiveLink.name, form.name, project.liveLinks]);

  const onInputChange = (field, value) => {
    setLiveLink({
      ...liveLink,
      [field]: value,
    });
  };

  const onCreate = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await PortfolioService.createLiveLink({
      ...liveLink,
      lastVerified: liveLink.lastVerified ? new Date(liveLink.lastVerified).toISOString() : '',
      notes,
      projectId: project._id,
    });
    finishLoading();

    if (result.error || !result.data.liveLink) {
      createToast({
        message: result.message || 'Creating live link has been failed',
        type: 'error',
      });
      return;
    }

    onSave(result.data.liveLink);
    addLiveLink(result.data.liveLink);
    createToast({
      message: 'New live link has been created successfully',
      type: 'success',
    });
  };

  const onEdit = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await PortfolioService.editLiveLink({
      ...liveLink,
      lastVerified: liveLink.lastVerified ? new Date(liveLink.lastVerified).toISOString() : '',
      notes,
    });
    finishLoading();

    if (result.error || !result.data.liveLink) {
      createToast({
        message: result.message || 'Editing live link has been failed',
        type: 'error',
      });
      return;
    }

    onSave(result.data.liveLink);
    editLiveLink(result.data.liveLink);
    createToast({
      message: 'Live link has been edited successfully',
      type: 'success',
    });
  };

  return (
    <Dialog
      title={editingLiveLink._id ? 'Edit Live Link' : 'Create Live Link'}
      className="edit-live-link-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          {
            editingLiveLink._id
              ? <Button className="btn-primary size-sm px-3" onClick={onEdit}>Save</Button>
              : <Button className="btn-primary size-sm px-3" onClick={onCreate}>Create</Button>
          }
        </>
      )}
    >
      <FormControl control={form.name} size="sm" onChange={onInputChange} />
      <FormControl control={form.link} size="sm" onChange={onInputChange} />
      <FormControl control={form.lastVerified} size="sm" onChange={onInputChange} />
      <FormControl control={form.userName} size="sm" onChange={onInputChange} />
      <FormControl control={form.password} size="sm" onChange={onInputChange} />
      <FormControl control={form.notes} size="sm" onChange={(_, value) => setNotes(value)} />
    </Dialog>
  );
};

EditLiveLinkModal.propTypes = {
  open: PropTypes.bool,
  project: PropTypes.object.isRequired,
  liveLink: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  addLiveLink: PropTypes.func.isRequired,
  editLiveLink: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

EditLiveLinkModal.defaultProps = {
  open: false,
  onSave: null,
  onClose: null,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addLiveLink: PortfolioAction.addLiveLink,
      editLiveLink: PortfolioAction.editLiveLink,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(EditLiveLinkModal);
