import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import './style.scss';

import { saveAs } from 'file-saver';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Add, DeleteOutline, EditOutlined, PublishOutlined, VisibilityOutlined } from '@material-ui/icons';
import CaseStudyPreviewModal from '../../Project/ImageGallery/CaseStudyTab/PreviewModal';
import { getCaseStudyHTML } from '../../../utils';
import ROUTES from '../../../constants/routes.json';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';

const CaseStudyTableActions = ({
  caseStudy, clients, setClients, setActiveProject, setActiveTab, createToast, startLoading, finishLoading,
}) => {
  const history = useHistory();
  const [preview, setPreview] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const getCaseStudyContent = async (caseStudyId) => {
    startLoading();
    const result = await PortfolioService.getCaseStudy(caseStudyId);
    finishLoading();
    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Cannot read case study content.',
      });
      return null;
    }

    const doc = result.data.caseStudy;
    if (doc.type === 'grapesjs') {
      doc.content = getCaseStudyHTML(doc.type, doc.content.html, doc.content.css);
    } else {
      doc.content = getCaseStudyHTML(doc.type, doc.content);
    }
    return doc;
  };

  const onPreview = async () => {
    const doc = await getCaseStudyContent(caseStudy._id);
    setPreview(doc);
  };

  const onEdit = () => {
    setActiveProject(caseStudy.project);
    setActiveTab('case-study');
    history.push(ROUTES.PROJECTS.url);
  };

  const onRemove = async () => {
    if (!caseStudy || !caseStudy._id || !window.confirm('Are you sure to remove this case study?')) {
      return;
    }

    startLoading();
    const result = await PortfolioService.removeCaseStudy(caseStudy);
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Removing case study has been failed.',
      });
    } else {
      setClients(clients.map((client) => ({
        ...client,
        projects: (client.projects || []).map((project) => ({
          ...project,
          caseStudy: project._id === caseStudy.projectId ? null : project.caseStudy,
        })),
      })));

      createToast({
        type: 'success',
        message: 'You have removed case study successfully.',
      });
    }
  };

  const onExportToPDF = async () => {
    startLoading();
    const result = await PortfolioService.exportCaseStudyToPDF({
      _id: caseStudy.projectId,
      name: caseStudy.projectName,
    });
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || 'Export case study has been failed.',
        type: 'error',
      });
    } else {
      createToast({
        message: 'Case study has been exported to PDF successfully',
        type: 'success',
      });
    }
  };

  const onExportToHTML = async () => {
    const doc = await getCaseStudyContent(caseStudy._id);
    saveAs(new Blob([doc.content]), `CaseStudy-${caseStudy.projectName}.html`);
    finishLoading();

    createToast({
      message: 'Case study has been exported to HTML successfully',
      type: 'success',
    });
  };

  const onShare = () => {
    const copyUrl = (e) => {
      e.preventDefault();
      e.clipboardData.setData('text/plain', `${window.location.host}/shared-case-study/${caseStudy._id}`);
      document.removeEventListener('copy', copyUrl);
      createToast({
        type: 'success',
        message: 'Copied shareable link.',
      });
    };
    document.addEventListener('copy', copyUrl);
    document.execCommand('copy');
  };

  return (
    <div className="table-actions">
      {caseStudy._id ? (
        <>
          <Tooltip title="Preview" placement="top" arrow>
            <IconButton className="btn-outline-secondary size-sm" onClick={onPreview}>
              <VisibilityOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton className="btn-outline-secondary size-sm ml-1" onClick={onEdit}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove" placement="top" arrow>
            <IconButton className="btn-outline-secondary size-sm ml-1" onClick={onRemove}>
              <DeleteOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export" placement="top" arrow>
            <IconButton
              className="btn-outline-secondary size-sm ml-1"
              onClick={onOpenMenu}
            >
              <PublishOutlined />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={onExportToPDF}>Export to PDF</MenuItem>
            <MenuItem onClick={onExportToHTML}>Export to HTML</MenuItem>
            <MenuItem onClick={onShare}>Link</MenuItem>
          </Menu>
        </>
      ) : (
        <Tooltip title="Create" placement="top" arrow>
          <IconButton className="btn-outline-secondary size-sm ml-1" onClick={onEdit}>
            <Add />
          </IconButton>
        </Tooltip>
      )}

      {preview && (
        <CaseStudyPreviewModal
          open
          title="Case Study Preview"
          type={preview.type}
          preview={preview.content}
          onClose={() => setPreview(null)}
        />
      )}
    </div>
  );
};

CaseStudyTableActions.propTypes = {
  caseStudy: PropTypes.object.isRequired,
  clients: PropTypes.array,
  setClients: PropTypes.func.isRequired,
  setActiveProject: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

CaseStudyTableActions.defaultProps = {
  clients: [],
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClients: PortfolioAction.setClients,
      setActiveProject: PortfolioAction.setActiveProject,
      setActiveTab: PortfolioAction.setActiveTab,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudyTableActions);
