import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import moment from 'moment';
import { Button } from '@material-ui/core';
import FormControl from '../../common/FormControl';
import { patchFormControl } from '../../common/FormControl/utils';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';

const MediaFeedback = ({
  media, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    feedback: {
      name: 'feedback',
      placeholder: 'Leave feedback here...',
      multiline: true,
    },
  }), []);

  const [content, setContent] = useState('');

  useEffect(() => {
    patchFormControl(form.feedback, content);
  }, [content, form.feedback]);

  const onSubmitFeedback = () => {
    if (!content)
      return;

    startLoading();
    PortfolioService.submitFeedback(media, content).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Submitting feedback has been failed',
          type: 'error',
        });
      } else {
        createToast({
          message: 'Feedback has been submitted successfully',
          type: 'success',
        });
        media.feedback = result.data.media.feedback;
        setContent('');
      }
    });
  };

  return (
    <div className="media-feedback w-100">
      <h5>Feedback</h5>
      <hr />

      {
        (media.feedback || []).map((feedback, index) => (
          <div key={index} className="feedback mb-2">
            <div className="text-dark" dangerouslySetInnerHTML={{ __html: feedback.content }} />
            <div className="text-muted text-sm text-right">
              <span className="font-weight-bold mr-2">{feedback.createdBy}</span>
              <span>{moment(feedback.createdAt).format('lll')}</span>
            </div>
            <hr />
          </div>
        ))
      }
      <div className="new-feedback">
        <FormControl control={form.feedback} size="sm" onChange={(_, value) => setContent(value)} />
        <div className="w-100 text-right">
          <Button
            className="btn-warning size-sm font-weight-bold px-5"
            disabled={!content.trim()}
            onClick={onSubmitFeedback}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

MediaFeedback.propTypes = {
  media: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

MediaFeedback.defaultProps = {
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(MediaFeedback);
