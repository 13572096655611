import React from 'react';
import PropTypes from 'prop-types';
import './FullLayout.scss';
import Header from '../components/Header';
import SideBar from '../components/SideBar';

export default function FullLayout({ children: content }) {
  return (
    <div className="full-layout">
      <SideBar />
      <div className="drawer-content w-0 h-full flex-grow-1 d-flex flex-column">
        <Header />
        <div className="layout-content h-0 flex-grow-1 overflow-auto">
          <div className="layout-top-left-corner" />
          {content}
        </div>
      </div>
    </div>
  );
}

FullLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};
