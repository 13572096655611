import React, { useState } from 'react';
import './style.scss';

import { Button, Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const reports = [
  { text: 'Last 50', value: 50 },
];

const ReportDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectReport = (report) => {
    handleClose();
    window.open(`/recent=${report.value}`, '_blank');
  };

  return (
    <div className="mr-2">
      <Button className="size-sm" onClick={handleClick}>
        Select Report
        <KeyboardArrowDown fontSize="small" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {reports.map((report) => (
          <MenuItem key={report.value} onClick={() => onSelectReport(report)}>{report.text}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ReportDropdown;
