require('dotenv').config();

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;

const API_SERVER = process.env.REACT_APP_API_SERVER;
const ASSETS_URL = process.env.REACT_APP_ASSETS_URL;
const PORTFOLIO_URL = process.env.REACT_APP_PORTFOLIO_URL;
const VIDEO_URL = process.env.REACT_APP_VIDEO_URL;
const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
const PROJECT_URL = process.env.REACT_APP_PROJECT_URL;
const CONTACT_URL = process.env.REACT_APP_CONTACT_URL;
const CASE_STUDY_URL = process.env.REACT_APP_CASE_STUDY_URL;
const REPORT_URL = process.env.REACT_APP_REPORT_URL;
const DRAFT_URL = process.env.REACT_APP_DRAFT_URL;

export default {
  TOKEN_KEY,
  API_SERVER,
  ASSETS_URL,
  PORTFOLIO_URL,
  VIDEO_URL,
  CLIENT_URL,
  PROJECT_URL,
  CONTACT_URL,
  CASE_STUDY_URL,
  REPORT_URL,
  DRAFT_URL,
};
