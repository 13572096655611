import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

import {
  TextField,
  FormControl as MaterialFormControl,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox, TextareaAutosize,
} from '@material-ui/core';
import { KeyboardArrowDown, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import SummerNote from 'react-summernote';
import 'summernote/dist/summernote-bs4.min.css';
import 'bootstrap';

import { getHtmlTextLength, validateFormControl } from './utils';
import { options as SummerNoteOptions } from '../../../constants/SummerNote';

const FormControl = ({
  control, startAdornment, endAdornment, className, size, fullWidth, onChange,
}) => {
  const [key, setKey] = useState(Math.random());
  const [type, setType] = useState(control.type);
  const [value, setValue] = useState(control.value);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (value !== control.value) {
      setValue(control.value);
      setKey(Math.random());
    }
  }, [control.value, value]);

  useEffect(() => {
    setErrorMessage(control.error);
  }, [control.error]);

  const validate = useCallback(() => {
    validateFormControl(control);
    setErrorMessage(control.error);
    return !control.invalid;
  }, [control]);

  const markAsTouched = useCallback(() => {
    if (!control.touched) {
      control.touched = true;
    }
    validate();
  }, [control.touched, validate]);

  const patchValue = useCallback((newValue) => {
    if (control.value !== newValue) {
      control.value = newValue;
      setKey(Math.random());
      setValue(newValue);
    }
  }, []);

  const setError = useCallback((error) => {
    control.error = error;
    control.invalid = Boolean(error);
    setErrorMessage(error);
  }, []);

  const refresh = useCallback(() => {
    setKey(Math.random());
  }, []);

  useEffect(() => {
    control.markAsTouched = markAsTouched;
    control.patchValue = patchValue;
    control.setError = setError;
    control.refresh = refresh;
  }, [markAsTouched, patchValue, setError, refresh]);

  const startAdornmentControl = useMemo(() => {
    if (startAdornment) {
      return (
        <InputAdornment position="start">
          {startAdornment}
        </InputAdornment>
      );
    }
    return null;
  }, [startAdornment]);

  const endAdornmentControl = useMemo(() => {
    if (endAdornment) {
      return (
        <InputAdornment position="end">
          {endAdornment}
        </InputAdornment>
      );
    }
    if (control.type === 'password') {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => setType(type === 'password' ? 'text' : 'password')}
            onMouseDown={(e) => e.preventDefault()}
          >
            {
              type === 'password'
                ? <VisibilityOutlined fontSize={size === 'sm' ? 'small' : 'default'} />
                : <VisibilityOffOutlined fontSize={size === 'sm' ? 'small' : 'default'} />
            }
          </IconButton>
        </InputAdornment>
      );
    }
    return null;
  }, [control.type, endAdornment, type]);

  const handleChange = (newValue) => {
    control.value = newValue;
    control.touched = true;
    validate();

    setValue(newValue);
    onChange(control.name, newValue);
  };

  const onSummerNoteInit = () => {
    const editor = document.querySelector(`.summer-editor-${control.name} .note-editable`);
    if (editor) {
      editor.innerHTML = control.value;
    }
  };

  const getFormControl = () => {
    if (control.type === 'autocomplete') {
      let getOptionLabel = control.getOptionLabel;
      if (!getOptionLabel && control.labelField) {
        getOptionLabel = (option) => option[control.labelField];
      }

      return (
        <Autocomplete
          key={key}
          {...control.controlOptions}
          defaultValue={value}
          options={control.options}
          autoSelect
          getOptionLabel={getOptionLabel}
          popupIcon={<KeyboardArrowDown fontSize="small" />}
          disabled={control.disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={control.placeholder}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
            />
          )}
          onChange={(_, newValue) => handleChange(newValue)}
        />
      );
    }

    if (control.type === 'textarea') {
      return (
        <>
          <TextareaAutosize
            key={key}
            name={control.name}
            value={value}
            placeholder={control.placeholder}
            className={errorMessage ? 'Mui-error' : ''}
            onChange={(e) => handleChange(e.target.value)}
          />
          {Boolean(control.error) && (
            <p className="MuiFormHelperText-root Mui-error">{control.error}</p>
          )}
        </>
      );
    }

    if (control.type === 'summer-note') {
      return (
        <>
          <SummerNote
            name={control.name}
            className={`summer-note-${control.name}`}
            value={value}
            options={{ ...SummerNoteOptions, ...control.controlOptions }}
            onChange={handleChange}
            onInit={onSummerNoteInit}
          />
          <div className="d-flex align-items-center">
            {Boolean(control.error) && (
              <p className="MuiFormHelperText-root Mui-error">{control.error}</p>
            )}
            {Boolean(control.maxLength) && (
              <div className={`ml-auto text-right mt-1 text-${control.invalid ? 'danger' : 'success'}`}>
                {`${getHtmlTextLength(value)} / ${control.maxLength}`}
              </div>
            )}
          </div>
        </>
      );
    }

    return (
      <TextField
        key={key}
        name={control.name}
        type={type}
        defaultValue={value}
        placeholder={control.placeholder}
        multiline={control.multiline}
        disabled={control.disabled}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={markAsTouched}
        InputProps={{
          startAdornment: startAdornmentControl,
          endAdornment: endAdornmentControl,
        }}
      />
    );
  };

  if (control.type === 'checkbox') {
    return (
      <MaterialFormControl className={`form-control-wrapper checkbox ${className} size-${size}`} fullWidth={fullWidth}>
        <FormControlLabel
          control={(
            <Checkbox
              key={key}
              name={control.name}
              checked={value}
              color="primary"
              disabled={control.disabled}
              onChange={(e) => handleChange(e.target.checked)}
            />
          )}
          label={control.label}
        />
      </MaterialFormControl>
    );
  }

  return (
    <MaterialFormControl className={`form-control-wrapper ${className} size-${size}`} fullWidth={fullWidth}>
      {Boolean(control.label) && (
        <label
          className={
            classnames({
              'text-danger': Boolean(errorMessage),
            })
          }
        >
          {control.label}
        </label>
      )}
      {getFormControl()}
    </MaterialFormControl>
  );
};

FormControl.propTypes = {
  control: PropTypes.object.isRequired,
  value: PropTypes.any,
  startAdornment: PropTypes.element,
  endAdornment: PropTypes.element,
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};

FormControl.defaultProps = {
  value: undefined,
  startAdornment: null,
  endAdornment: null,
  className: '',
  size: 'md',
  fullWidth: true,
  onChange: () => {},
};

export default FormControl;
