import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import './style.scss';

import { Button } from '@material-ui/core';
import FormControl from '../../components/common/FormControl';
import ROUTES from '../../constants/routes.json';
import * as AuthService from '../../services/auth.service';
import Validators from '../../components/common/FormControl/validators';
import { getFormData, validateFormGroup } from '../../components/common/FormControl/utils';
import { createToast } from '../../store/actions/toast.action';
import { finishLoading, startLoading } from '../../store/actions/common.action';

const ForgotPassword = () => {
  const form = useMemo(() => ({
    email: {
      name: 'email',
      label: 'Email Address',
      validators: [Validators.required(), Validators.email()],
    },
  }), []);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validateFormGroup(form))
      return;

    const { email } = getFormData(form);

    dispatch(startLoading());
    AuthService.forgotPassword(email).then((result) => {
      dispatch(finishLoading());
      if (result.error) {
        dispatch(createToast({
          type: 'error',
          message: result.error || 'Login failed.',
        }));
      } else {
        history.push(ROUTES.CONFIRM_EMAIL.url, {
          email,
        });
      }
    });
  };

  return (
    <div className="reset-password">
      <div className="card-box">
        <h1 className="text-primary text-center mb-4">Forgot Password</h1>
        <p className="text-muted font-13p mb-4">
          Enter your email address and we will send you an email with instructions to reset your password.
        </p>
        <form onSubmit={onSubmit}>
          <FormControl control={form.email} />
          <Button type="submit" fullWidth className="btn btn-warning font-weight-bold">Send Email</Button>
        </form>
      </div>

      <div className="text-center mt-3">
        Already have account?
        <Link to="/sign-in" className="text-primary ml-2">
          <b>Sign In</b>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
