import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import moment from 'moment-timezone';
import './ImageEdit.scss';

import { Button, Grid } from '@material-ui/core';
import ImageChangeModal from '../../ImageChangeModal';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import mobileDevices from '../../../../constants/templates';
import config from '../../../../constants/config';
import {
  compareObject,
  getCropFromDevice,
  getCroppedImage,
  prePopulateVersion,
} from '../../../../utils';
import MediaCarousel from '../ProgressTab/MediaCarousel';
import FormControl from '../../../common/FormControl';
import Validators from '../../../common/FormControl/validators';
import { patchFormData, patchFormControl, validateFormGroup } from '../../../common/FormControl/utils';
import { MEDIA_STATUSES } from '../../../../constants';

let imageFile;

const ImageEdit = ({
  editingImage, layout, hasExtraInfo, clients, tags, user, editMedia, onImageEdit, setTags,
  startUploading, finishUploading, createToast, startLoading, finishLoading, finishAllLoading,
}) => {
  const form = useMemo(() => ({
    frame: {
      name: 'frame',
      type: 'autocomplete',
      placeholder: 'Select frame',
      options: ['Custom (No frame)'].concat(mobileDevices.map((device) => device.name)),
      controlOptions: {
        disableClearable: true,
      },
    },
    section: {
      name: 'section',
      type: 'autocomplete',
      label: 'Section',
      placeholder: 'Select section',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
      validators: [Validators.required()],
    },
    subSection: {
      name: 'subSection',
      type: 'autocomplete',
      label: 'Sub Section',
      placeholder: 'Select sub section',
      options: [],
      controlOptions: {
        freeSolo: true,
      },
    },
    tags: {
      name: 'tags',
      type: 'autocomplete',
      label: 'Tags',
      placeholder: 'Add tags',
      value: [],
      options: [],
      controlOptions: {
        multiple: true,
        freeSolo: true,
      },
      validators: [Validators.required()],
    },
    status: {
      name: 'status',
      type: 'autocomplete',
      label: 'Status',
      placeholder: 'Select status',
      options: MEDIA_STATUSES,
      validators: [Validators.required()],
    },
    version: {
      name: 'version',
      label: 'Version',
      placeholder: 'Version',
      validators: [Validators.required()],
    },
    lastModifiedDate: {
      name: 'lastModifiedDate',
      label: 'Last Modified Date',
      disabled: true,
    },
    lastModifiedBy: {
      name: 'lastModifiedBy',
      label: 'Last Modified By',
      disabled: true,
    },
    description: {
      name: 'description',
      type: 'summer-note',
      label: 'Description *',
      maxLength: 400,
      validators: [Validators.required(), Validators.maxLength(400, true)],
    },
  }), []);

  const [imageInfo, setImageInfo] = useState({
    section: '',
    subSection: '',
    status: '',
    version: '',
    tags: [],
  });
  const [description, setDescription] = useState({
    content: undefined,
    length: 0,
  });
  const [cropInfo, setCropInfo] = useState({
    device: 'Custom (No frame)',
    crop: {
      unit: '%',
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    },
  });
  const [orgCrop, setOrgCrop] = useState(undefined);
  const [imageUrl, setImageUrl] = useState('');
  const [loadingError, setLoadingError] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [sourceImage, setSourceImage] = useState(null);

  const currentProject = useMemo(() => {
    if (typeof editingImage.project === 'object' && editingImage.project.images)
      return editingImage.project;

    const projectId = typeof editingImage.project === 'object' ? editingImage.project._id : editingImage.project;
    let project = null;
    for (const client of clients) {
      project = client.projects.find((p) => p._id === projectId);
      if (project)
        break;
    }
    return project;
  }, [editingImage.project, clients]);

  const sectionNames = useMemo(() => {
    if (!currentProject)
      return [];

    let sections = currentProject.images.map((media) => media.section);
    sections = [...new Set(sections)];
    return sections;
  }, [currentProject]);

  const subSectionNames = useMemo(() => {
    if (!currentProject || !imageInfo.section)
      return [];

    let subSections = [];
    currentProject.images.forEach((media) => {
      if (media.section === imageInfo.section && media.subSection)
        subSections.push(media.subSection);
    });
    subSections = [...new Set(subSections)];
    return subSections;
  }, [currentProject, imageInfo.section]);

  const tagNames = useMemo(() => (
    tags.sort((tag1, tag2) => tag2.cnt - tag1.cnt).map((tag) => tag.name)
  ), [tags]);

  useEffect(() => {
    Object.assign(form.section, { options: sectionNames });
  }, [form.section, sectionNames]);

  useEffect(() => {
    Object.assign(form.subSection, { options: subSectionNames });
  }, [form.subSection, subSectionNames]);

  useEffect(() => {
    Object.assign(form.tags, { options: tagNames });
  }, [form.tags, tagNames]);

  useEffect(() => {
    patchFormControl(form.frame, cropInfo.device);
  }, [cropInfo.device, form.frame]);

  useEffect(() => {
    patchFormData(form, imageInfo);
  }, [form, imageInfo]);

  useEffect(() => {
    patchFormData(form, {
      lastModifiedDate: moment(editingImage.lastModified).tz('America/Los_Angeles').format('LLLL'),
      lastModifiedBy: editingImage.lastModifiedBy,
    });
  }, [editingImage, form]);

  const previousVersions = useMemo(() => {
    const versions = editingImage.version.split('.');
    if (versions.length < 3)
      return [];

    const currentVersion = parseInt(versions[versions.length - 1], 10);
    versions[versions.length - 1] = '';
    const versionPrefix = versions.join('.');
    return currentProject.images.filter((image) => {
      if (!image.version.startsWith(versionPrefix))
        return false;
      const version = parseInt(image.version.substr(versionPrefix.length), 10);
      return version < currentVersion;
    });
  }, [currentProject, editingImage.version]);

  useEffect(() => {
    setLoadingError(false);
    setImageUrl('');

    setSourceImage({
      preview: editingImage.type.startsWith('image')
        ? `${config.PORTFOLIO_URL}/${editingImage._id}_org.${editingImage.type.split(/\W/)[1]}?q=${editingImage.updatedAt}`
        : `${config.VIDEO_URL}/${editingImage._id}.${editingImage.type.split(/\W/)[1]}?q=${editingImage.updatedAt}`,
      type: editingImage.type,
      changed: false,
    });

    if (editingImage.type.startsWith('image')) {
      // startLoading();
      fetch(`${config.PORTFOLIO_URL}/${editingImage._id}_org.${editingImage.type.split(/\W/)[1]}?q=${editingImage.updatedAt}`)
        .then((res) => res.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => setImageUrl(url));

      if (editingImage.crop && editingImage.crop.unit) {
        const { device, ...crop } = editingImage.crop;
        const deviceInfo = mobileDevices.find((d) => d.name === device);
        if (deviceInfo) {
          crop.aspect = deviceInfo.screenWidth / deviceInfo.screenHeight;
        }
        setCropInfo({ device, crop });
        setOrgCrop({ device, crop });
      } else {
        setCropInfo({
          device: 'Custom (No frame)',
          crop: {
            unit: '%',
            x: 0,
            y: 0,
            width: 100,
            height: 100,
          },
        });
        setOrgCrop(undefined);
      }
    }

    setImageInfo({
      section: editingImage.section || '',
      subSection: editingImage.subSection || '',
      status: editingImage.status || '',
      version: editingImage.version || '',
      tags: editingImage.tags.map((tag) => tag.name),
      description: editingImage.description,
    });
    setDescription(editingImage.description);
  }, [editingImage, startLoading]);

  useEffect(() => (
    () => {
      finishAllLoading();
    }
  ), [finishAllLoading]);

  if (loadingError) {
    return (
      <p className="no-preview-msg text-center my-5 py-5">
        Loading image has been failed.
      </p>
    );
  }

  if (!currentProject)
    return null;

  const populateVersion = (section, subSection) => {
    if (section === editingImage.section && subSection === editingImage.subSection)
      return editingImage.version;

    return prePopulateVersion(currentProject, section, subSection);
  };

  const onInputChange = (field, value) => {
    const newInfo = {
      ...imageInfo,
      [field]: value,
    };
    if (field === 'section' && imageInfo.subSection === imageInfo.section) {
      newInfo.subSection = newInfo.section;
    }
    if (field === 'section' || field === 'subSection') {
      newInfo.version = populateVersion(newInfo.section, newInfo.subSection) || newInfo.version;
    }
    setImageInfo(newInfo);
  };

  const onCropChange = (value) => {
    setCropInfo({
      ...cropInfo,
      crop: value,
    });
  };

  const onDescriptionChange = (_, value) => {
    setDescription(value);
  };

  const onSave = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    let imageName = '';
    let newImage = null;
    if (sourceImage.type.startsWith('image') && (!orgCrop || sourceImage.changed || !compareObject(orgCrop, cropInfo))) {
      newImage = await getCroppedImage(imageFile, cropInfo.device, cropInfo.crop, sourceImage.type) || sourceImage.file;
      imageName = `${editingImage._id}.${editingImage.type.split(/\W/)[1]}`;
    }
    let oldImage = null;
    if (sourceImage.changed) {
      oldImage = sourceImage.file;
      if (!imageName) {
        if (sourceImage.type.startsWith('image')) {
          imageName = `${editingImage._id}_org.${editingImage.type.split(/\W/)[1]}`;
        } else {
          imageName = `${editingImage._id}.${editingImage.type.split(/\W/)[1]}`;
        }
      }
    }

    const crop = JSON.stringify({
      device: cropInfo.device,
      ...cropInfo.crop,
    });

    const currentType = sourceImage.type ? sourceImage.type.split(/\W/)[0] : 'media';
    startUploading(`Uploading ${currentType}. Please wait...`);
    const result = await PortfolioService.editMedia({
      _id: editingImage._id,
      section: imageInfo.section,
      subSection: imageInfo.subSection,
      status: imageInfo.status,
      version: imageInfo.version,
      lastModified: new Date(),
      description,
      tags: imageInfo.tags,
      type: sourceImage.type,
      crop,
      imageName,
      newImage,
      oldImage,
      lastModifiedBy: user.name,
      saveHistory: true,
    });
    finishUploading();

    if (result.error || !result.data.image) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: result.message || 'Edit has been failed.',
      });
    } else {
      const { image, tags } = result.data;
      if (newImage && image.type.startsWith('image')) {
        image.url = URL.createObjectURL(newImage);
      }
      const type = image.type ? image.type.split(/\W/)[0] : 'media';
      createToast({
        type: 'success',
        message: `${type.substr(0, 1)
          .toUpperCase() + type.substr(1)} has been edited successfully.`,
      });
      onImageEdit(image);
      editMedia(image);
      setTags(tags);
    }
  };

  const onDeviceChanged = (_name, deviceName) => {
    setCropInfo({
      device: deviceName,
      crop: getCropFromDevice(imageFile, deviceName),
    });
  };

  const onImageLoaded = (image) => {
    imageFile = image;
    finishLoading();
    setLoadingError(false);
  };

  const onImageLoadError = () => {
    if (imageUrl) {
      imageFile = null;
      finishLoading();
      setLoadingError(true);
    }
  };

  const onChangeImage = (image) => {
    setImageUrl(image.preview);
    setSourceImage({
      file: image,
      preview: image.preview,
      type: image.type,
      changed: true,
    });

    if (image.type.startsWith('image')) {
      const img = new Image();
      img.src = image.preview;
      img.onload = () => {
        setCropInfo({
          ...cropInfo,
          crop: getCropFromDevice(img, cropInfo.device),
        });
      };
    }
  };

  if (!sourceImage)
    return null;

  return (
    <>
      <div className="image-edit-section">
        <Grid container className="content" spacing={3}>
          <Grid item sm={layout === '2-columns' ? 6 : 12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="d-flex-center flex-column mb-3">
                  {
                    sourceImage.type.startsWith('image')
                      ? (
                        <>
                          <FormControl className="size-sm" control={form.frame} onChange={onDeviceChanged} />
                          <ReactCrop
                            src={imageUrl}
                            crop={cropInfo.crop}
                            onChange={(newCrop, percentCrop) => onCropChange(percentCrop)}
                            onImageLoaded={onImageLoaded}
                            onImageError={onImageLoadError}
                            keepSelection
                          />
                        </>
                      )
                      : (
                        // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video
                          className="mw-100"
                          controls
                          src={sourceImage.preview}
                        />
                      )
                  }
                  <span className="text-sm hover-underline cursor-pointer mt-2" onClick={() => setShowChangeModal(true)}>Change</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                <FormControl size="sm" control={form.section} className="mb-0" onChange={onInputChange} />
              </Grid>
              <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                <FormControl size="sm" control={form.subSection} className="mb-0" onChange={onInputChange} />
              </Grid>
              <Grid item xs={12}>
                <FormControl size="sm" control={form.tags} className="mb-0" onChange={onInputChange} />
              </Grid>
              {!hasExtraInfo && (
                <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                  <FormControl size="sm" control={form.status} className="mb-0" onChange={onInputChange} />
                </Grid>
              )}
              <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                <FormControl size="sm" control={form.version} className="mb-0" onChange={onInputChange} />
              </Grid>
              <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                <FormControl size="sm" control={form.lastModifiedDate} className="mb-0" />
              </Grid>
              <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                <FormControl size="sm" control={form.lastModifiedBy} className="mb-0" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={layout === '2-columns' ? 6 : 12}>
            <FormControl size="sm" control={form.description} className="mb-0" onChange={onDescriptionChange} />
            {hasExtraInfo && (
              <Grid item xs={12} sm={layout === '2-columns' ? 12 : 6}>
                <FormControl size="sm" control={form.status} className="mb-0" onChange={onInputChange} />
              </Grid>
            )}
            {hasExtraInfo && (
              <div className="mt-3">
                <label className="text-muted text-sm mb-2">Previous Versions</label>
                {/* <PastVersions image={editingImage} /> */}
                <MediaCarousel medias={previousVersions} noMediaMessage="No previous version screens" />
              </div>
            )}
          </Grid>
        </Grid>

        <div className="footer mt-3">
          <Button className="btn-warning size-sm font-weight-bold px-5" onClick={onSave}>Save</Button>
        </div>
      </div>
      {showChangeModal && (
        <ImageChangeModal
          open
          image={sourceImage}
          onClose={() => setShowChangeModal(false)}
          onChange={onChangeImage}
        />
      )}
    </>
  );
};

ImageEdit.propTypes = {
  editingImage: PropTypes.object.isRequired,
  layout: PropTypes.string,
  hasExtraInfo: PropTypes.bool,
  tags: PropTypes.array,
  clients: PropTypes.array,
  user: PropTypes.object.isRequired,
  onImageEdit: PropTypes.func,
  editMedia: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startUploading: PropTypes.func.isRequired,
  finishUploading: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  finishAllLoading: PropTypes.func.isRequired,
};

ImageEdit.defaultProps = {
  layout: '1-column',
  hasExtraInfo: false,
  tags: [],
  clients: [],
  onImageEdit: null,
};

const mapStateToProps = (store) => ({
  tags: store.portfolioReducer.tags,
  clients: store.portfolioReducer.clients,
  user: store.authReducer.user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editMedia: PortfolioAction.editMedia,
      setTags: PortfolioAction.setTags,
      createToast: ToastAction.createToast,
      startUploading: CommonAction.startUploading,
      finishUploading: CommonAction.finishUploading,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
      finishAllLoading: CommonAction.finishAllLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageEdit);
