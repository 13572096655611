import { saveAs } from 'file-saver';
import moment from 'moment';
import HttpService from './http.service';
import config from '../constants/config';
import StorageService from './storage.service';
import store from '../store';
import { setUploadingProgress } from '../store/actions/common.action';
import { makePPT } from '../utils/export-ppt';

const tokenKey = config.TOKEN_KEY;

export function getClients(params = {}) {
  return HttpService.get('clients', {}, params)
    .then((result) => result.data);
}

export function createClient(client) {
  return HttpService.post('clients', client)
    .then((result) => result.data);
}

export function editClient(client) {
  return HttpService.put(`clients/${client._id}`, client)
    .then((result) => result.data);
}

export function removeClient(clientId) {
  return HttpService.remove(`clients/${clientId}`)
    .then((result) => result.data);
}

export function setClientLogo(clientId, logo, logoName) {
  const formData = new FormData();
  formData.append('clientId', clientId);
  formData.append('fileName', logoName);
  formData.append('logo', logo, logo.name);

  return HttpService.post('client-logo', formData)
    .then((result) => result.data);
}

export function removeClientLogo(clientId) {
  return HttpService.remove('client-logo', { clientId })
    .then((result) => result.data);
}

export function getProjects(clientId, fullInfo = true) {
  if (fullInfo) {
    return HttpService.get(`clients/${clientId}`)
      .then((result) => {
        if (!result.data.error) {
          StorageService.setItem(tokenKey, result.data.data.token);
        }
        return result.data;
      });
  }

  return HttpService.get(`projects?clientId=${clientId}`)
    .then((result) => result.data);
}

export function getProject(projectId, expand = []) {
  return HttpService.get(`projects/${projectId}`, {}, { params: { expand } })
    .then((result) => result.data);
}

export function createProject(project) {
  return HttpService.post('projects', project)
    .then((result) => result.data);
}

export function editProject(project) {
  return HttpService.put(`projects/${project._id}`, project)
    .then((result) => result.data);
}

export function removeProject(project) {
  return HttpService.remove(`projects/${project._id}`)
    .then((result) => result.data);
}

export function setProjectLogo(project, logo, logoName) {
  const formData = new FormData();
  formData.append('projectId', project._id);
  formData.append('fileName', logoName);
  formData.append('logo', logo, logo.name);

  return HttpService.post('project-logo', formData)
    .then((result) => result.data);
}

export function removeProjectLogo(project) {
  return HttpService.remove('project-logo', { projectId: project._id })
    .then((result) => result.data);
}

export function setProjectBackground(project, background, backgroundName) {
  const formData = new FormData();
  formData.append('projectId', project._id);
  formData.append('fileName', backgroundName);
  formData.append('background', background, background.name);

  return HttpService.post('project-background', formData)
    .then((result) => result.data);
}

export function removeProjectBackground(project) {
  return HttpService.remove('project-background', { projectId: project._id })
    .then((result) => result.data);
}

export function setProjectNote(project, note, noteName) {
  const formData = new FormData();
  formData.append('projectId', project._id);
  formData.append('fileName', noteName);
  formData.append('note', note, noteName);

  return HttpService.post('project-note', formData)
    .then((result) => result.data);
}

export function removeProjectNote(project) {
  return HttpService.remove('project-note', { projectId: project._id })
    .then((result) => result.data);
}

export function getCaseStudies(params = {}) {
  return HttpService.get('project-case-studies', {}, params)
    .then((result) => result.data);
}

export function getCaseStudy(caseStudyId) {
  if (typeof caseStudyId === 'object')
    caseStudyId = caseStudyId._id;
  return HttpService.get(`project-case-study/${caseStudyId}`).then((result) => result.data);
}

export function createCaseStudy(project, caseStudy) {
  return HttpService.post('project-case-study', {
    type: caseStudy.type,
    title: caseStudy.title,
    location: caseStudy.location,
    industry: caseStudy.industry,
    content: caseStudy.content,
    projectId: project._id,
  }).then((result) => result.data);
}

export function editCaseStudy(caseStudy) {
  return HttpService.put(`project-case-study/${caseStudy._id}`, {
    type: caseStudy.type,
    title: caseStudy.title,
    location: caseStudy.location,
    industry: caseStudy.industry,
    content: caseStudy.content,
  }).then((result) => result.data);
}

export function removeCaseStudy(caseStudy) {
  return HttpService.remove(`project-case-study/${caseStudy._id}`)
    .then((result) => result.data);
}

export function getTags() {
  return HttpService.get('tags')
    .then((result) => result.data);
}

export function uploadImage(image, imageId = 0, imageCount = 1) {
  const formData = new FormData();
  formData.append('project', image.project);
  formData.append('section', image.section);
  formData.append('subSection', image.subSection);
  formData.append('status', image.status);
  formData.append('version', image.version);
  formData.append('description', image.description);
  formData.append('tags', image.tags);
  formData.append('crop', image.crop);
  formData.append('type', image.orgImage.type);
  formData.append('images', image.orgImage);
  formData.append('lastModifiedBy', image.lastModifiedBy);

  if (image.newImage) {
    formData.append('images', image.newImage);
  }

  return HttpService.post('images', formData, {}, {
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = Math.round(((imageId + progressEvent.loaded / progressEvent.total) / imageCount) * 100);

      store.dispatch(setUploadingProgress(uploadPercentage));
    },
  }).then((result) => result.data);
}

export function editMedia(image) {
  const formData = new FormData();
  formData.append('section', image.section || '');
  formData.append('subSection', image.subSection || '');
  formData.append('status', image.status || '');
  formData.append('version', image.version || '');
  formData.append('lastModified', image.lastModified || '');
  formData.append('description', image.description || '');
  formData.append('tags', image.tags);
  formData.append('type', image.type);
  formData.append('crop', image.crop);
  formData.append('imageName', image.imageName);
  formData.append('lastModifiedBy', image.lastModifiedBy);
  formData.append('saveHistory', image.saveHistory);
  formData.append('feedback', image.feedback || '');
  if (image.newImage) {
    formData.append('images', image.newImage);
  }
  if (image.oldImage) {
    formData.append('images', image.oldImage);
  }

  return HttpService.put(`images/${image._id}`, formData, {}, {
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      store.dispatch(setUploadingProgress(uploadPercentage));
    },
  }).then((result) => result.data);
}

export function getImage(imageId) {
  return HttpService.get(`images/${imageId}`)
    .then((result) => result.data);
}

export function removeMedia(imageId) {
  return HttpService.remove(`images/${imageId}`)
    .then((result) => result.data);
}

export function setImagesOrder(orders) {
  return HttpService.put('/reorder-images', { orders })
    .then((result) => result.data);
}

export function download(url, filename) {
  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.download = filename;
  a.target = '_blank';

  document.body.appendChild(a);
  a.click();

  // saveAs(url, filename);
}

export function exportToPDF(images) {
  const imageIds = images.map((image) => image._id);
  return HttpService.post('export/pdf', { imageIds }, {})
    .then((result) => {
      if (!result.data.error) {
        const filename = `${images[0].project.name}-${moment().format('YYYY-MM-DD')}.pdf`;
        download(`${config.API_SERVER}/export/download-file/${result.data.file}/${encodeURIComponent(filename)}`, filename);
      }
      return result.data;
    });
}

export function exportToHTML(images) {
  const imageIds = images.map((image) => image._id);
  return HttpService.post('export/html', { imageIds }, {})
    .then((result) => {
      if (!result.data.error) {
        const filename = `${images[0].project.name}-${moment().format('YYYY-MM-DD')}.zip`;
        download(`${config.API_SERVER}/export/download-file/${result.data.file}/${encodeURIComponent(filename)}`, filename);
      }
      return result.data;
    });
}

export function exportToPPT(images) {
  const imageIds = images.map((image) => image._id);
  return makePPT(imageIds)
    .then((pptx) => {
      if (pptx) {
        const filename = `${images[0].project.name}-${moment().format('YYYY-MM-DD')}.pptx`;
        return pptx.writeFile(filename);
      }
    }).then((result) => {
      if (!result) {
        return {
          error: true,
          message: 'Export to PPT has been failed',
        };
      }
      return {
        error: false,
      };
    });
}

export function downloadMedias(mediaIds) {
  return HttpService.post('export/medias', { mediaIds }, {})
    .then((result) => {
      if (!result.data.error) {
        const filename = `Portfolio-${new Date().toISOString()}.zip`;
        download(`${config.API_SERVER}/export/download-file/${result.data.file}/${encodeURIComponent(filename)}`, filename);
      }
      return result.data;
    });
}

export function exportCaseStudyToPDF(project) {
  return HttpService.post(
    'export/case-study/pdf',
    { projectId: project._id },
    {},
    { json: false, responseType: 'blob' },
  ).then((result) => {
    if (!result.data.error) {
      saveAs(result.data, `CaseStudy-${project.name}.pdf`);
    }
    return result.data;
  });
}

export function getContacts(userId) {
  return HttpService.get(`users/${userId}/contacts`)
    .then((result) => result.data);
}

export function createContact(contact) {
  return HttpService.post('contacts', contact)
    .then((result) => result.data);
}

export function editContact(contact) {
  return HttpService.put(`contacts/${contact._id}`, contact)
    .then((result) => result.data);
}

export function removeContact(contactId) {
  return HttpService.remove(`contacts/${contactId}`)
    .then((result) => result.data);
}

export function setContactImage(contactId, image, imageName) {
  const formData = new FormData();
  formData.append('contactId', contactId);
  formData.append('imageName', imageName);
  formData.append('image', image, image.name);

  return HttpService.post(`contacts/${contactId}/image`, formData)
    .then((result) => result.data);
}

export function removeContactImage(contactId) {
  return HttpService.remove(`contacts/${contactId}/image`)
    .then((result) => result.data);
}

export function getRecentImages(count) {
  return HttpService.get(`recent-images?count=${count}`)
    .then((result) => result.data);
}

export function uploadCaseStudyImage(image, projectId, type) {
  const formData = new FormData();
  formData.append('projectId', projectId);
  formData.append('type', type);
  formData.append('image', image);

  return HttpService.post('image-manager', formData)
    .then((result) => result.data);
}

export function removeCaseStudyImage(filePath) {
  return HttpService.remove('image-manager', { filePath })
    .then((result) => result.data);
}

export function createLiveLink(liveLink) {
  return HttpService.post('project-live-links', liveLink)
    .then((result) => result.data);
}

export function editLiveLink(liveLink) {
  return HttpService.put(`project-live-links/${liveLink._id}`, liveLink)
    .then((result) => result.data);
}

export function removeLiveLink(liveLink) {
  return HttpService.remove(`project-live-links/${liveLink._id}`)
    .then((result) => result.data);
}

export function globalSearch(section, subSection, tags) {
  // if (!sections.length && !subSections.length && !tags.length) {
  //   return ({
  //     images: [],
  //   });
  // }

  return HttpService.post('global-search', {
    sections: section ? [section] : [],
    subSections: subSection ? [subSection] : [],
    tags,
  }).then((result) => result.data);
}

export function massEditMedias(imageIds, changes) {
  return HttpService.put('images-mass-edit', {
    imageIds,
    changes,
  }).then((result) => result.data);
}

export function massMoveImages(imageIds, targetProjectId) {
  return HttpService.put('images-mass-move', {
    imageIds,
    targetProjectId,
  }).then((result) => result.data);
}

export function getRecentChanges(project, section = undefined) {
  return HttpService.get(
    `projects/${project._id}/recent-changes`,
    {},
    { params: { section } },
  ).then((result) => result.data);
}

export function submitFeedback(image, feedback) {
  return HttpService.post(`image/${image._id}/feedback`, { feedback }).then((result) => result.data);
}

export function getGlobalSearchInfo() {
  return HttpService.get('global-search-info').then((result) => result.data);
}

export function uploadReport(file, fileName) {
  const formData = new FormData();
  formData.append('fileName', fileName);
  formData.append('file', file);

  return HttpService.post('report', formData)
    .then((result) => result.data);
}

export function listDrafts(project) {
  return HttpService.get('drafts', {}, { params: { project: project._id } }).then((result) => result.data);
}

export function getDraft(draftId) {
  return HttpService.get(`drafts/${draftId}`).then((result) => result.data);
}

export function uploadDraft(draft) {
  const formData = new FormData();
  formData.append('title', draft.title);
  formData.append('description', draft.description);
  formData.append('type', draft.type);
  formData.append('project', draft.project);
  formData.append('image', draft.file);

  return HttpService.post('drafts', formData, {}, {
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);

      store.dispatch(setUploadingProgress(uploadPercentage));
    },
  }).then((result) => result.data);
}

export function updateDraft(draft) {
  const formData = new FormData();
  formData.append('title', draft.title);
  formData.append('description', draft.description);
  formData.append('type', draft.type);
  formData.append('image', draft.file);

  return HttpService.put(`drafts/${draft._id}`, formData, {}, {
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);

      store.dispatch(setUploadingProgress(uploadPercentage));
    },
  }).then((result) => result.data);
}

export function deleteDraft(draftId) {
  return HttpService.remove(`drafts/${draftId}`).then((result) => result.data);
}

export function clearDrafts(project) {
  return HttpService.remove('drafts', { project: project._id }).then((result) => result.data);
}

export function reorderDrafts(drafts) {
  return HttpService.post('reorder-drafts', { drafts }).then((result) => result.data);
}

export function exportReport(project) {
  return HttpService.post('export/report', { project: project._id })
    .then((result) => result.data);
}

export function moveProject(projectId, clientId) {
  return HttpService.post(`projects/${projectId}/move`, {
    clientId,
  }).then((result) => result.data);
}

export function listWeeklyReports(project) {
  return HttpService.get('weekly-reports', {}, { params: { project: project._id } })
    .then((result) => result.data);
}

export function createWeeklyReport(report) {
  const formData = new FormData();
  formData.append('project', report.project);
  formData.append('accomplish', report.accomplish);
  formData.append('plan', report.plan);
  if (report.blockers)
    formData.append('blockers', report.blockers);
  if (report.actions)
    formData.append('actions', report.actions);
  if (report.status)
    formData.append('status', report.status);
  if (report.color)
    formData.append('color', report.color);
  if (report.audio) {
    formData.append('audio', report.audio);
  }

  return HttpService.post('weekly-reports', formData)
    .then((result) => result.data);
}

export function updateWeeklyReport(report) {
  const formData = new FormData();
  formData.append('accomplish', report.accomplish);
  formData.append('plan', report.plan);
  if (report.blockers)
    formData.append('blockers', report.blockers);
  if (report.actions)
    formData.append('actions', report.actions);
  if (report.status)
    formData.append('status', report.status);
  if (report.color)
    formData.append('color', report.color);
  if (report.fileName) {
    formData.append('fileName', report.fileName);
  }
  if (report.audio) {
    formData.append('audio', report.audio);
  }

  return HttpService.put(`weekly-reports/${report._id}`, formData)
    .then((result) => result.data);
}

export function getWeeklyReportedProjects() {
  return HttpService.get('weekly-reported-projects')
    .then((result) => result.data);
}

export function removeWeeklyReport(report) {
  return HttpService.remove(`weekly-reports/${report._id}`)
    .then((result) => result.data);
}
