import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ToastAction from '../../../store/actions/toast.action';

import './style.scss';

const Toast = ({
  id, title, message, type, newestOnTop, deleteToast, closeTime,
}) => {
  const onClose = () => {
    deleteToast(id);
  };

  useEffect(() => {
    window.setTimeout(onClose, closeTime);
  });

  return (
    <div className={`toast bg-${type} ${newestOnTop ? 'top' : ''} d-flex`} onClick={onClose}>
      <div className="d-flex align-items-center justify-content-center w-25">
        {type === 'success' && <i className="fa fa-check" />}
        {type === 'error' && <i className="fa fa-times" />}
        {type === 'warning' && <i className="fa fa-exclamation-triangle" />}
        {type === 'info' && <i className="fa fa-info" />}
      </div>
      <div className="toast-body d-flex justify-content-center flex-column pl-0">
        {!!title && <h6 className="text-white">{title}</h6>}
        <span>{message}</span>
      </div>
    </div>
  );
};

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  newestOnTop: PropTypes.bool,
  closeTime: PropTypes.number,
  deleteToast: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  title: '',
  type: 'success',
  newestOnTop: true,
  closeTime: 3500,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    deleteToast: ToastAction.deleteToast,
  },
  dispatch,
);

export default connect(null, mapDispatchToProps)(Toast);
