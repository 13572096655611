import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';
import moment from 'moment-timezone';
import queryString from 'query-string';
import config from '../../constants/config';

import * as ToastAction from '../../store/actions/toast.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as CommonAction from '../../store/actions/common.action';

const ImageView = ({
  match, createToast, startLoading, finishLoading, location, history,
}) => {
  const [image, setImage] = useState(undefined);
  const queryParams = queryString.parse(location.search) || {};
  const { from } = queryParams;

  useEffect(() => {
    const imageId = match.params.id;
    startLoading();
    PortfolioService.getImage(imageId).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message,
          type: 'error',
        });
        setImage(null);
      } else {
        setImage(result.image);
      }
    });
  }, [createToast, finishLoading, match.params.id, startLoading]);

  const handleBackClicked = React.useCallback(() => {
    if (from === 'recent') {
      history.goBack();
    }
  }, [from, history]);

  return (
    <div className="image-view-page">
      <div className="header">
        { from && (
          <button
            className="btn btn-bordered ml-3 back-btn mt-3"
            onClick={handleBackClicked}
          >
            <i className="fa fa-chevron-left" />
            Back
          </button>
        ) }
        <h1 className="page-title text-center">{image?.subSection || image?.section}</h1>
      </div>
      <div className="image-detail">
        {
          image
            ? (
              <>
                <h2 className="page-title pl-2 mb-4">{image.subSection}</h2>
                {
                  image.type.startsWith('image')
                    ? <img src={`${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`} alt={image._id} />
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    : <video controls src={`${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`} />
                }
                <div className="tag-list">
                  <i className="fa fa-tags" />
                  {
                    image.tags.map((tag) => (<span key={tag._id} className="tag">{tag.name}</span>))
                  }
                </div>
                <div className="image-info">
                  <h5>Project:</h5>
                  <p>{image.project?.name || '---'}</p>
                </div>
                <div className="image-info">
                  <h5>Section:</h5>
                  <p>{image.section || '---'}</p>
                </div>
                <div className="image-info">
                  <h5>Sub section:</h5>
                  <p>{image.subSection || '---'}</p>
                </div>
                <div className="image-info">
                  <h5>Status:</h5>
                  <p>{image.status || '---'}</p>
                </div>
                <div className="image-info">
                  <h5>Last modified:</h5>
                  <p>{moment(image.lastModified).tz('America/Los_Angeles').format('LLLL')}</p>
                </div>
                <div className="image-info">
                  <h5>Description:</h5>
                  <div className="description" dangerouslySetInnerHTML={{ __html: image.description }} />
                </div>
              </>
            )
            : `${image === null ? 'Image Not Found' : 'Loading...'}`
        }
      </div>
    </div>
  );
};

ImageView.propTypes = {
  match: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ImageView);
