import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import './style.scss';

import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import * as PortfolioService from '../../../../../services/portfolio.service';
import * as ToastAction from '../../../../../store/actions/toast.action';
import * as CommonAction from '../../../../../store/actions/common.action';

const ExportButton = ({
  caseStudy, activeProject, getPreview, createToast, startLoading, finishLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onExportToPDF = async () => {
    if (!caseStudy._id) {
      createToast({
        message: 'Please save case study before export.',
        type: 'warning',
      });
      return;
    }
    startLoading();
    // await onSave(false);
    const result = await PortfolioService.exportCaseStudyToPDF(activeProject);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || 'Export case study has been failed.',
        type: 'error',
      });
    } else {
      createToast({
        message: 'Case study has been exported to PDF successfully',
        type: 'success',
      });
    }
  };

  const onExportToHTML = async () => {
    if (!caseStudy._id) {
      createToast({
        message: 'Please save case study before export.',
        type: 'warning',
      });
      return;
    }
    startLoading();
    // await onSave(false);
    const html = getPreview();
    saveAs(new Blob([html]), `CaseStudy-${activeProject.name}.html`);
    finishLoading();

    createToast({
      message: 'Case study has been exported to HTML successfully',
      type: 'success',
    });
  };

  const onShare = () => {
    if (!caseStudy._id) {
      createToast({
        message: 'Please save case study before export.',
        type: 'warning',
      });
      return;
    }

    const copyUrl = (e) => {
      e.preventDefault();
      e.clipboardData.setData('text/plain', `${window.location.host}/shared-case-study/${activeProject.caseStudy}`);
      document.removeEventListener('copy', copyUrl);
      createToast({
        type: 'success',
        message: 'Copied shareable link.',
      });
    };
    document.addEventListener('copy', copyUrl);
    document.execCommand('copy');
  };

  return (
    <div className="export-button">
      <Tooltip title="Export" placement="top" arrow>
        <IconButton
          className="btn-outline-secondary size-sm ml-2"
          onClick={onOpenMenu}
        >
          <PublishOutlined />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onExportToPDF}>Export to PDF</MenuItem>
        <MenuItem onClick={onExportToHTML}>Export to HTML</MenuItem>
        <MenuItem onClick={onShare}>Link</MenuItem>
      </Menu>
    </div>
  );
};

ExportButton.propTypes = {
  caseStudy: PropTypes.object.isRequired,
  activeProject: PropTypes.object.isRequired,
  getPreview: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(ExportButton);
