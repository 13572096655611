import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import './MediaEditModal.scss';

import { IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline, ShareOutlined, VisibilityOutlined } from '@material-ui/icons';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as CommonAction from '../../../../store/actions/common.action';
import ImageEdit from './ImageEdit';
import PreviewModal from './PreviewModal';
import Dialog from '../../../common/Dialog';

const MediaEditModal = ({
  open, media, removeMedia, onClose, createToast, startLoading, finishLoading,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const onRemove = () => {
    if (!window.confirm('Are you sure to remove this media?')) {
      return;
    }

    startLoading();
    PortfolioService.removeMedia(media._id).then((result) => {
      finishLoading();

      if (result.error) {
        createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Removing media has been failed.',
        });
      } else {
        createToast({
          type: 'success',
          message: 'You have removed media successfully.',
        });
        removeMedia(media);
        onClose();
      }
    });
  };

  const onShare = () => {
    const copyImageUrl = (e) => {
      e.preventDefault();
      e.clipboardData.setData('text/plain', `${window.location.host}/share/${media._id}`);
      document.removeEventListener('copy', copyImageUrl);
      createToast({
        type: 'success',
        message: 'Copied shareable link.',
      });
    };

    document.addEventListener('copy', copyImageUrl);
    document.execCommand('copy');
  };

  if (!media) {
    return null;
  }

  return (
    <Dialog
      title="Edit Screenshot"
      open={open}
      onClose={onClose}
      headerActions={(
        <>
          <Tooltip title="Preview" placement="top" arrow>
            <IconButton
              className="btn-outline-secondary size-sm mr-1"
              onClick={() => setShowPreview(true)}
            >
              <VisibilityOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove" placement="top" arrow>
            <IconButton
              className="btn-outline-secondary size-sm mr-1"
              onClick={onRemove}
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Share" placement="top" arrow>
            <IconButton
              className="btn-outline-secondary size-sm mr-1"
              onClick={onShare}
            >
              <ShareOutlined />
            </IconButton>
          </Tooltip>
        </>
      )}
    >
      <ImageEdit editingImage={media} onImageEdit={onClose} />
      {
        showPreview
          ? <PreviewModal show image={media} onClose={() => setShowPreview(false)} />
          : null
      }
    </Dialog>
  );
};

MediaEditModal.propTypes = {
  media: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

MediaEditModal.defaultProps = {
  open: false,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeMedia: PortfolioAction.removeMedia,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(MediaEditModal);
