export const ROLES = {
  ADMIN: 'admin',
  PM: 'project manager',
  SALES: 'sales',
  USER: 'user',
  CLIENT: 'client',
};

export const MEDIA_STATUSES = [
  'In Design',
  'In Development',
  'Accepted',
];
