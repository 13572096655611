import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { ShareOutlined } from '@material-ui/icons';
import FormControl from '../../../common/FormControl';
import RecentChanges from './RecentChanges';
import ImageEdit from '../ScreenshotsTab/ImageEdit';
import MediaFeedback from '../../MediaFeedback';
import { setFormControlOptions } from '../../../common/FormControl/utils';
import * as ToastAction from '../../../../store/actions/toast.action';

const ProgressTab = ({
  project, showProjectTitle, createToast,
}) => {
  const form = useMemo(() => ({
    section: {
      name: 'section',
      type: 'autocomplete',
      placeholder: 'Select section',
      options: [],
    },
  }), []);

  const [activeImage, setActiveImage] = useState(null);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const names = project.images.map((image) => image.section);
    const sections = [...new Set(names)];
    setFormControlOptions(form.section, {
      options: sections,
    });
  }, [form.section, project.images]);

  const onShare = () => {
    const copyImageUrl = (e) => {
      e.preventDefault();
      e.clipboardData.setData('text/plain', `${window.location.host}/progress/${project._id}`);
      document.removeEventListener('copy', copyImageUrl);
      createToast({
        type: 'success',
        message: 'Copied shareable link.',
      });
    };

    document.addEventListener('copy', copyImageUrl);
    document.execCommand('copy');
  };

  if (!project.images.length) {
    return (
      <p className="no-data">
        No screenshots are available.
      </p>
    );
  }

  return (
    <div className="progress-tab-content">
      <div className="tab-header filter-box d-flex-center align-items-center">
        {showProjectTitle && (
          <h4 className="mb-0 text-ellipsis mr-3">{project.name}</h4>
        )}
        <div className="section-select d-flex-center align-items-center flex-grow-1">
          <label className="text-muted text-sm mb-0 mr-3">Filter Section: </label>
          <FormControl
            control={form.section}
            size="sm"
            fullWidth={false}
            className="mb-0"
            onChange={(_, value) => setActiveSection(value)}
          />
        </div>

        <Tooltip title="Share" placement="top" arrow>
          <IconButton
            className="btn-outline-secondary size-sm ml-2"
            onClick={onShare}
          >
            <ShareOutlined />
          </IconButton>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div className="tab-header">
            <RecentChanges
              project={project}
              section={activeSection}
              activeImage={activeImage}
              onSelectImage={(image) => setActiveImage(image)}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          {activeImage && (
            <>
              <div className="tab-header">
                <ImageEdit
                  editingImage={{ ...activeImage, project }}
                  layout="2-columns"
                  hasExtraInfo
                  onImageEdit={(image) => setActiveImage(image)}
                />
              </div>
              <div className="tab-header">
                <MediaFeedback media={activeImage} />
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

ProgressTab.propTypes = {
  project: PropTypes.object.isRequired,
  showProjectTitle: PropTypes.bool,
  createToast: PropTypes.func.isRequired,
};

ProgressTab.defaultProps = {
  showProjectTitle: false,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      createToast: ToastAction.createToast,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(ProgressTab);
