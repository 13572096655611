import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import moment from 'moment-timezone';
import { Button, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { DeleteOutline, SaveOutlined, VisibilityOutlined } from '@material-ui/icons';
import FormControl from '../../../common/FormControl';
import FroalaEditor from './FroalaEditor';
import GrapesJsEditor from './GrapesJsEditor';
import ExportButton from './ExportButton';
import CaseStudyPreviewModal from './PreviewModal';

import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import { getCaseStudyHTML } from '../../../../utils';
import { patchFormData } from '../../../common/FormControl/utils';

let editor = null;
let lastSavedTimer;

const CaseStudyTab = ({
  active, activeProject, setCaseStudy: setProjectCaseStudy, setHasUnsavedData, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    title: {
      name: 'title',
      label: 'Title',
      placeholder: 'Title',
    },
    location: {
      name: 'location',
      label: 'Location',
      placeholder: 'Location',
    },
    industry: {
      name: 'industry',
      label: 'Company Type / Industry',
      placeholder: 'Company Type / Industry',
    },
  }), []);

  const [editorType, setEditorType] = useState('froala');
  const [caseStudy, setCaseStudy] = useState(undefined);
  const [previewHtml, setPreviewHtml] = useState(null);

  const updateLastSaved = useCallback(() => {
    if (caseStudy && caseStudy.updatedAt && caseStudy.updatedBy) {
      const el = document.getElementById('last-saved');
      if (el) {
        el.innerText = `Last saved: ${moment(caseStudy.updatedAt).fromNow()} by ${caseStudy.updatedBy.name}`;
      }
    }
  }, [caseStudy]);

  useEffect(() => {
    updateLastSaved();
    lastSavedTimer = setInterval(updateLastSaved, 1000 * 60);

    return () => {
      clearInterval(lastSavedTimer);
    };
  }, [updateLastSaved]);

  useEffect(() => {
    setCaseStudy(undefined);

    if (activeProject && activeProject.caseStudy) {
      PortfolioService.getCaseStudy(activeProject.caseStudy).then((result) => {
        if (result.error) {
          createToast({
            type: 'error',
            message: result.message || 'Cannot read case study content.',
          });
          setCaseStudy(null);
        } else {
          const doc = result.data.caseStudy;
          if (doc.type !== 'grapesjs') {
            doc.type = 'froala';
          }
          setCaseStudy(doc);
        }

        setHasUnsavedData(false);
      });
    } else {
      setCaseStudy(null);
    }
  }, [activeProject, createToast, setHasUnsavedData]);

  useEffect(() => {
    if (!caseStudy) {
      patchFormData(form, {
        title: '',
        location: '',
        industry: '',
      });
    } else {
      patchFormData(form, caseStudy);
    }
  }, [caseStudy, form]);

  const onCreateCaseStudy = () => {
    setCaseStudy({
      type: editorType,
      content: null,
    });
    setHasUnsavedData(true);
  };

  const onEditorInit = (controller) => {
    editor = controller;
  };

  const onRemove = async () => {
    if (!window.confirm('Are you sure to remove this case study?')) {
      return;
    }

    if (caseStudy._id) {
      startLoading();
      const result = await PortfolioService.removeCaseStudy(caseStudy);
      finishLoading();

      if (result.error) {
        createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Removing case study has been failed.',
        });
      } else {
        setProjectCaseStudy(null);
        setHasUnsavedData(false);
        createToast({
          type: 'success',
          message: 'You have removed case study successfully.',
        });
      }
    } else {
      setProjectCaseStudy(null);
      setHasUnsavedData(false);
    }
  };

  const onSave = async (showToast = true) => {
    if (caseStudy.type === 'froala') {
      caseStudy.content = editor.html.get();
    } else {
      const content = editor.store();
      caseStudy.content = {
        html: content.html,
        css: content.css,
        components: JSON.parse(content.components),
        styles: JSON.parse(content.styles),
        assets: JSON.parse(content.assets).filter((asset) => !asset.category || asset.category === 'Custom'),
      };
    }

    startLoading();
    let result;
    if (!caseStudy._id) {
      result = await PortfolioService.createCaseStudy(activeProject, caseStudy);
    } else {
      result = await PortfolioService.editCaseStudy(caseStudy);
    }
    finishLoading();

    if (showToast) {
      if (result.error) {
        createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Saving case study has been failed.',
        });
      } else {
        createToast({
          type: 'success',
          message: 'You have saved case study successfully.',
        });
        setHasUnsavedData(false);
      }
    }

    if (!result.error) {
      setProjectCaseStudy(result.data.caseStudy._id);
    }
  };

  const getPreview = () => {
    if (caseStudy.type === 'grapesjs') {
      const css = editor.getCss();
      const html = editor.getHtml();
      return getCaseStudyHTML(caseStudy.type, html, css);
    }

    const content = editor.html.get();
    return getCaseStudyHTML(caseStudy.type, content, '', activeProject.name);
  };

  const onPreview = () => {
    setPreviewHtml(getPreview());
  };

  if (!activeProject || !active) {
    return null;
  }

  if (caseStudy === undefined) {
    return (
      <div className="gallery-content py-5 my-5">
        <p className="w-100 text-center">Loading...</p>
      </div>
    );
  }

  if (!caseStudy) {
    return (
      <div className="gallery-content p-5">
        <div className="align-self-center text-center">
          <p className="tab-content-message mb-4">No case study has been created for this project yet.</p>
          <h4 className="mb-3">Click here to get started</h4>
          <RadioGroup
            row
            value={editorType}
            className="justify-content-center"
            onChange={(_, value) => setEditorType(value)}
          >
            <FormControlLabel value="froala" control={<Radio color="primary" />} label="Froala Editor" className="mr-5" />
            <FormControlLabel value="grapesjs" control={<Radio color="primary" />} label="GrapesJS Builder" />
          </RadioGroup>
          <Button
            className="btn-primary size-sm font-weight-bold px-4 mt-3"
            onClick={onCreateCaseStudy}
          >
            Create Case Study
          </Button>
        </div>
      </div>
    );
  }

  const onInputChange = (field, value) => {
    caseStudy[field] = value;
  };

  return (
    <div className="case-study-editor">
      <div className="tab-header">
        <div className="editor-header d-flex align-items-center justify-content-between">
          <h3 className="font-weight-normal text-ellipsis mr-4">
            Case Study for&nbsp;
            <b>{activeProject.name}</b>
          </h3>
          <div className="d-flex align-items-center">
            <Tooltip title="Discard" placement="top" arrow>
              <IconButton
                className="btn-outline-secondary size-sm ml-2"
                onClick={onRemove}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save" placement="top" arrow>
              <IconButton
                className="btn-outline-secondary size-sm ml-2"
                onClick={onSave}
              >
                <SaveOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Preview" placement="top" arrow>
              <IconButton
                className="btn-outline-secondary size-sm ml-2"
                onClick={onPreview}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
            <ExportButton caseStudy={caseStudy} activeProject={activeProject} getPreview={getPreview} />
          </div>
        </div>

        {
          caseStudy.updatedAt
            ? (
              <div
                id="last-saved"
                className="text-xs text-right mt-1"
              >
                Last saved:&nbsp;
                {moment(caseStudy.updatedAt).fromNow()}
                &nbsp;by&nbsp;
                {caseStudy.updatedBy.name}
              </div>
            )
            : null
        }

        <Grid container spacing={2} className="mt-3">
          <Grid item xs={12} sm={4}>
            <FormControl control={form.title} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl control={form.location} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl control={form.industry} size="sm" className="mb-0" onChange={onInputChange} />
          </Grid>
        </Grid>
      </div>

      {
        caseStudy.type === 'grapesjs'
          ? (
            <GrapesJsEditor
              caseStudy={caseStudy}
              project={activeProject}
              onEditorInit={onEditorInit}
            />
          )
          : (
            <FroalaEditor
              caseStudy={caseStudy}
              project={activeProject}
              onEditorInit={onEditorInit}
            />
          )
      }

      {
        previewHtml !== null
          ? (
            <CaseStudyPreviewModal
              title="Case Study Preview"
              type={caseStudy.type}
              preview={previewHtml}
              open
              onClose={() => setPreviewHtml(null)}
            />
          )
          : null
      }
    </div>
  );
};

CaseStudyTab.propTypes = {
  active: PropTypes.bool,
  activeProject: PropTypes.object,
  setCaseStudy: PropTypes.func.isRequired,
  setHasUnsavedData: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

CaseStudyTab.defaultProps = {
  active: false,
  activeProject: null,
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setCaseStudy: PortfolioAction.setCaseStudy,
      setHasUnsavedData: PortfolioAction.setHasUnsavedData,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudyTab);
