import ACTION from '../actionTypes';
import * as AuthService from '../../services/auth.service';

export function setClient(client) {
  return {
    type: ACTION.SET_CLIENT,
    client,
  };
}

export function setClients(clients) {
  return {
    type: ACTION.SET_CLIENTS,
    clients,
  };
}

export function setTags(tags) {
  return {
    type: ACTION.SET_TAGS,
    tags,
  };
}

export function addClient(client) {
  return {
    type: ACTION.ADD_CLIENT,
    client,
  };
}

export function editClient(client) {
  return {
    type: ACTION.EDIT_CLIENT,
    client,
  };
}

export function addProject(project) {
  return {
    type: ACTION.ADD_PROJECT,
    project,
  };
}

export function editProject(project) {
  return {
    type: ACTION.EDIT_PROJECT,
    project,
  };
}

export function setActiveProject(project) {
  if (project) {
    AuthService.setLastVisit({
      project: project._id,
    }).then();
  }

  return {
    type: ACTION.SET_ACTIVE_PROJECT,
    project,
  };
}

export function setActiveTab(tab) {
  AuthService.setLastVisit({ tab }).then();

  return {
    type: ACTION.SET_ACTIVE_TAB,
    tab,
  };
}

export function removeProject(project) {
  return {
    type: ACTION.REMOVE_PROJECT,
    project,
  };
}

export function toggleSideBar() {
  return {
    type: ACTION.TOGGLE_SIDE_BAR,
  };
}

export function showSideBar(show) {
  return {
    type: ACTION.SHOW_SIDE_BAR,
    show,
  };
}

export function setCurrentPage(url) {
  return {
    type: ACTION.SET_CURRENT_PAGE,
    url,
  };
}

export function setUploadingInfo(info) {
  return {
    type: ACTION.SET_UPLOADING_INFO,
    info,
  };
}

export function goToNextImage() {
  return {
    type: ACTION.GO_TO_NEXT_IMAGE,
  };
}

export function goToPrevImage() {
  return {
    type: ACTION.GO_TO_PREV_IMAGE,
  };
}

export function addMedia(image) {
  return {
    type: ACTION.ADD_MEDIA,
    image,
  };
}

export function editMedia(image) {
  return {
    type: ACTION.EDIT_MEDIA,
    image,
  };
}

export function removeMedia(image) {
  return {
    type: ACTION.REMOVE_MEDIA,
    image,
  };
}

export function removeMedias(medias) {
  return {
    type: ACTION.REMOVE_MEDIAS,
    medias,
  };
}

export function setEditingImage(image) {
  return {
    type: ACTION.SET_EDITING_IMAGE,
    image,
  };
}

export function setPreviewImage(image) {
  return {
    type: ACTION.PREVIEW_IMAGE,
    image,
  };
}

export function setEditingProject(project) {
  return {
    type: ACTION.SET_EDITING_PROJECT,
    project,
  };
}

export function toggleImageSelect(image) {
  return {
    type: ACTION.TOGGLE_IMAGE_SELECT,
    image,
  };
}

export function setSelectedImages(images) {
  return {
    type: ACTION.SET_SELECTED_IMAGES,
    images,
  };
}

export function setFilteredImages(images) {
  return {
    type: ACTION.SET_FILTERED_IMAGES,
    images,
  };
}

export function setContacts(contacts) {
  return {
    type: ACTION.SET_CONTACTS,
    contacts,
  };
}

export function addContact(contact) {
  return {
    type: ACTION.ADD_CONTACT,
    contact,
  };
}

export function editContact(contact) {
  return {
    type: ACTION.EDIT_CONTACT,
    contact,
  };
}

export function removeContact(contactId) {
  return {
    type: ACTION.REMOVE_CONTACT,
    contactId,
  };
}

export function setEditingContact(contact) {
  return {
    type: ACTION.SET_EDITING_CONTACT,
    contact,
  };
}

export function reorderMedias(orders) {
  return {
    type: ACTION.REORDER_MEDIAS,
    orders,
  };
}

export function setCaseStudy(caseStudyId) {
  return {
    type: ACTION.SET_CASE_STUDY,
    caseStudyId,
  };
}

export function addLiveLink(liveLink) {
  return {
    type: ACTION.ADD_LIVE_LINK,
    liveLink,
  };
}

export function editLiveLink(liveLink) {
  return {
    type: ACTION.EDIT_LIVE_LINK,
    liveLink,
  };
}

export function removeLiveLink(liveLink) {
  return {
    type: ACTION.REMOVE_LIVE_LINK,
    liveLink,
  };
}

export function setGlobalSearch(isGlobal) {
  return {
    type: ACTION.SET_GLOBAL_SEARCH,
    isGlobal,
  };
}

export function massEditMedias(imageIds, changes) {
  return {
    type: ACTION.MASS_EDIT_MEDIAS,
    imageIds,
    changes,
  };
}

export function massMoveImages(images, targetClient, targetProject) {
  return {
    type: ACTION.MASS_MOVE_IMAGES,
    images,
    targetClient,
    targetProject,
  };
}

export function showEditPanel(show) {
  return {
    type: ACTION.SHOW_EDIT_PANEL,
    show,
  };
}

export function setHasUnsavedData(hasData) {
  return {
    type: ACTION.SET_HAS_UNSAVED_DATA,
    hasData,
  };
}

export function setCapturedImages(images) {
  return {
    type: ACTION.SET_CAPTURED_IMAGES,
    images,
  };
}

export function moveProject(project, client) {
  return {
    type: ACTION.MOVE_PROJECT,
    project,
    client,
  };
}

export function setWeeklyReportedProjects(projects) {
  return {
    type: ACTION.SET_WEEKLY_REPORTED_PROJECTS,
    projects,
  };
}

export function addWeeklyReportedProject(project) {
  return {
    type: ACTION.ADD_WEEKLY_REPORTED_PROJECT,
    project,
  };
}

export function removeWeeklyReportedProject(project) {
  return {
    type: ACTION.REMOVE_WEEKLY_REPORTED_PROJECT,
    project,
  };
}

export function setProjectFilter(filter) {
  return {
    type: ACTION.SET_PROJECT_FILTER,
    filter,
  };
}
