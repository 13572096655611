import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import ProgressTab from '../../components/Project/ImageGallery/ProgressTab';
import * as ToastAction from '../../store/actions/toast.action';
import * as PortfolioService from '../../services/portfolio.service';
import * as CommonAction from '../../store/actions/common.action';

const SharedProgress = ({
  match, createToast, startLoading, finishLoading,
}) => {
  const [project, setProject] = useState(undefined);

  useEffect(() => {
    const projectId = match.params.id;
    startLoading();
    PortfolioService.getProject(projectId, ['images']).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          message: result.message || 'Loading project has been failed',
          type: 'error',
        });
        setProject(null);
      } else {
        setProject(result.data.project);
      }
    });
  }, [createToast, finishLoading, match.params.id, startLoading]);

  return (
    <div className="shared-progress">
      {
        project
          ? (
            <ProgressTab project={project} showProjectTitle />
          )
          : (
            <div className="w-100 h-100 d-flex-center font-20p">
              {project === undefined ? 'Loading ...' : 'Project Not Found'}
            </div>
          )
      }
    </div>
  );
};

SharedProgress.propTypes = {
  match: PropTypes.object.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(SharedProgress);
