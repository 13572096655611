import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
// import 'grapesjs-react/grapesjs-react';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
// import 'grapesjs-lory-slider/dist/grapesjs-lory-slider.min';
// import 'grapesjs-tabs/dist/grapesjs-tabs.min';
// import 'grapesjs-custom-code/dist/grapesjs-custom-code.min';
// import 'grapesjs-touch/dist/grapesjs-touch.min';
// import 'grapesjs-parser-postcss/dist/grapesjs-parser-postcss.min';
// import 'grapesjs-tooltip/dist/grapesjs-tooltip.min';
// import 'grapesjs-tui-image-editor/dist/grapesjs-tui-image-editor.min';
// import 'grapesjs-typed/dist/grapesjs-typed.min';
import 'grapesjs-style-bg/dist/grapesjs-style-bg.min';
import '../../../../../utils/grapesjs-video';

import './indiex.scss';
import * as ToastAction from '../../../../../store/actions/toast.action';
import * as CommonAction from '../../../../../store/actions/common.action';
import * as PortfolioService from '../../../../../services/portfolio.service';
import config from '../../../../../constants/config';
import GrapesJsTemplate from '../../../../../constants/GrapesJsTemplate';
import * as PortfolioAction from '../../../../../store/actions/portfolio.action';

let updateCnt = 0;

const GrapesJsEditor = ({
  caseStudy, project, onEditorInit, setHasUnsavedData, createToast, startLoading, finishLoading,
}) => {
  const [contents, setContents] = useState(null);

  useEffect(() => {
    updateCnt = 0;

    if (caseStudy) {
      let { content } = caseStudy;
      if (!content) {
        content = { ...GrapesJsTemplate };
      }

      content.assets = content.assets.filter((asset) => !asset.category || asset.category === 'Custom');
      project.images.forEach((image) => {
        if (image.type.startsWith('image')) {
          content.assets.push({
            type: 'image',
            category: 'Screenshot',
            name: `Screenshot:<br />${image._id}.${image.type.split(/\W/)[1]}`,
            src: `${config.PORTFOLIO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`,
          });
        } else {
          content.assets.push({
            type: 'video',
            category: 'Video',
            name: `Video:<br />${image._id}.${image.type.split(/\W/)[1]}`,
            src: `${config.VIDEO_URL}/${image._id}.${image.type.split(/\W/)[1]}?q=${image.updatedAt}`,
          });
        }
      });
      if (project.logo) {
        content.assets.push({
          type: 'image',
          category: 'Logo',
          name: `Logo:<br />${project.logo}`,
          src: `${config.PROJECT_URL}/${project.logo}?q=${project.updatedAt}`,
        });
      }
      if (project.background) {
        content.assets.push({
          type: 'image',
          category: 'Background',
          name: `Background:<br />${project.background}`,
          src: `${config.PROJECT_URL}/${project.background}?q=${project.updatedAt}`,
        });
      }
      content.assets = content.assets.concat(GrapesJsTemplate.assets);

      setContents(content);
    }
  }, [project, caseStudy]);

  useEffect(() => {
    const el = document.getElementById('gjs');
    if (el && !el.className) {
      const editor = grapesjs.init({
        avoidInlineStyle: 1,
        height: '100vh',
        container: '#gjs',
        fromElement: 1,
        showOffsets: 1,
        assetManager: {
          assets: contents.assets,
          upload: `${config.API_SERVER}/image-manager`,
          uploadName: 'image',
          params: { projectId: project._id, type: 'grapesjs' },
          credentials: 'omit',
          multiUpload: false,
        },
        storageManager: {
          id: 'gjs-',
          type: 'remote',
          autoload: false,
          autosave: false,
          urlLoad: `${config.API_SERVER}/grapes-js/${caseStudy._id}`,
          credentials: 'omit',
        },
        selectorManager: { componentFirst: true },
        styleManager: { clearProperties: 1 },
        plugins: [
          'grapesjs-lory-slider',
          'grapesjs-tabs',
          'grapesjs-custom-code',
          'grapesjs-touch',
          'grapesjs-parser-postcss',
          'grapesjs-tooltip',
          'grapesjs-tui-image-editor',
          'grapesjs-typed',
          'grapesjs-style-bg',
          'gjs-preset-webpage',
          'gjs-video',
        ],
        pluginsOpts: {
          'grapesjs-lory-slider': {
            sliderBlock: {
              category: 'Extra',
            },
          },
          'grapesjs-tabs': {
            tabsBlock: {
              category: 'Extra',
            },
          },
          'grapesjs-typed': {
            block: {
              category: 'Extra',
              content: {
                type: 'typed',
                'type-speed': 40,
                strings: [
                  'Text row one',
                  'Text row two',
                  'Text row three',
                ],
              },
            },
          },
          'gjs-preset-webpage': {
            modalImportTitle: 'Import Template',
            modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
            modalImportContent(editor) {
              return `${editor.getHtml()}<style>${editor.getCss()}</style>`;
            },
            filestackOpts: null, // { key: 'AYmqZc2e8RLGLE7TGkX3Hz' },
            aviaryOpts: false,
            blocksBasicOpts: { flexGrid: 1 },
            customStyleManager: [{
              name: 'General',
              buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom'],
              properties: [{
                name: 'Alignment',
                property: 'float',
                type: 'radio',
                defaults: 'none',
                list: [
                  { value: 'none', className: 'fa fa-times' },
                  { value: 'left', className: 'fa fa-align-left' },
                  { value: 'right', className: 'fa fa-align-right' },
                ],
              },
              { property: 'position', type: 'select' },
              ],
            }, {
              name: 'Dimension',
              open: false,
              buildProps: ['width', 'flex-width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
              properties: [{
                id: 'flex-width',
                type: 'integer',
                name: 'Width',
                units: ['px', '%'],
                property: 'flex-basis',
                toRequire: 1,
              }, {
                property: 'margin',
                properties: [
                  { name: 'Top', property: 'margin-top' },
                  { name: 'Right', property: 'margin-right' },
                  { name: 'Bottom', property: 'margin-bottom' },
                  { name: 'Left', property: 'margin-left' },
                ],
              }, {
                property: 'padding',
                properties: [
                  { name: 'Top', property: 'padding-top' },
                  { name: 'Right', property: 'padding-right' },
                  { name: 'Bottom', property: 'padding-bottom' },
                  { name: 'Left', property: 'padding-left' },
                ],
              }],
            }, {
              name: 'Typography',
              open: false,
              buildProps: [
                'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-decoration', 'text-shadow',
              ],
              properties: [
                { name: 'Font', property: 'font-family' },
                { name: 'Weight', property: 'font-weight' },
                { name: 'Font color', property: 'color' },
                {
                  property: 'text-align',
                  type: 'radio',
                  defaults: 'left',
                  list: [
                    { value: 'left', name: 'Left', className: 'fa fa-align-left' },
                    { value: 'center', name: 'Center', className: 'fa fa-align-center' },
                    { value: 'right', name: 'Right', className: 'fa fa-align-right' },
                    { value: 'justify', name: 'Justify', className: 'fa fa-align-justify' },
                  ],
                }, {
                  property: 'text-decoration',
                  type: 'radio',
                  defaults: 'none',
                  list: [
                    { value: 'none', name: 'None', className: 'fa fa-times' },
                    { value: 'underline', name: 'underline', className: 'fa fa-underline' },
                    { value: 'line-through', name: 'Line-through', className: 'fa fa-strikethrough' },
                  ],
                }, {
                  property: 'text-shadow',
                  properties: [
                    { name: 'X position', property: 'text-shadow-h' },
                    { name: 'Y position', property: 'text-shadow-v' },
                    { name: 'Blur', property: 'text-shadow-blur' },
                    { name: 'Color', property: 'text-shadow-color' },
                  ],
                }],
            }, {
              name: 'Decorations',
              open: false,
              buildProps: ['opacity', 'border-radius', 'border', 'box-shadow', 'background-bg'],
              properties: [{
                type: 'slider',
                property: 'opacity',
                defaults: 1,
                step: 0.01,
                max: 1,
                min: 0,
              }, {
                property: 'border-radius',
                properties: [
                  { name: 'Top', property: 'border-top-left-radius' },
                  { name: 'Right', property: 'border-top-right-radius' },
                  { name: 'Bottom', property: 'border-bottom-left-radius' },
                  { name: 'Left', property: 'border-bottom-right-radius' },
                ],
              }, {
                property: 'box-shadow',
                properties: [
                  { name: 'X position', property: 'box-shadow-h' },
                  { name: 'Y position', property: 'box-shadow-v' },
                  { name: 'Blur', property: 'box-shadow-blur' },
                  { name: 'Spread', property: 'box-shadow-spread' },
                  { name: 'Color', property: 'box-shadow-color' },
                  { name: 'Shadow type', property: 'box-shadow-type' },
                ],
              }, {
                id: 'background-bg',
                property: 'background',
                type: 'bg',
              }],
            }, {
              name: 'Extra',
              open: false,
              buildProps: ['transition', 'perspective', 'transform'],
              properties: [{
                property: 'transition',
                properties: [
                  { name: 'Property', property: 'transition-property' },
                  { name: 'Duration', property: 'transition-duration' },
                  { name: 'Easing', property: 'transition-timing-function' },
                ],
              }, {
                property: 'transform',
                properties: [
                  { name: 'Rotate X', property: 'transform-rotate-x' },
                  { name: 'Rotate Y', property: 'transform-rotate-y' },
                  { name: 'Rotate Z', property: 'transform-rotate-z' },
                  { name: 'Scale X', property: 'transform-scale-x' },
                  { name: 'Scale Y', property: 'transform-scale-y' },
                  { name: 'Scale Z', property: 'transform-scale-z' },
                ],
              }],
            }, {
              name: 'Flex',
              open: false,
              properties: [{
                name: 'Flex Container',
                property: 'display',
                type: 'select',
                defaults: 'block',
                list: [
                  { value: 'block', name: 'Disable' },
                  { value: 'flex', name: 'Enable' },
                ],
              }, {
                name: 'Flex Parent',
                property: 'label-parent-flex',
                type: 'integer',
              }, {
                name: 'Direction',
                property: 'flex-direction',
                type: 'radio',
                defaults: 'row',
                list: [{
                  value: 'row',
                  name: 'Row',
                  className: 'icons-flex icon-dir-row',
                  title: 'Row',
                }, {
                  value: 'row-reverse',
                  name: 'Row reverse',
                  className: 'icons-flex icon-dir-row-rev',
                  title: 'Row reverse',
                }, {
                  value: 'column',
                  name: 'Column',
                  title: 'Column',
                  className: 'icons-flex icon-dir-col',
                }, {
                  value: 'column-reverse',
                  name: 'Column reverse',
                  title: 'Column reverse',
                  className: 'icons-flex icon-dir-col-rev',
                }],
              }, {
                name: 'Justify',
                property: 'justify-content',
                type: 'radio',
                defaults: 'flex-start',
                list: [{
                  value: 'flex-start',
                  className: 'icons-flex icon-just-start',
                  title: 'Start',
                }, {
                  value: 'flex-end',
                  title: 'End',
                  className: 'icons-flex icon-just-end',
                }, {
                  value: 'space-between',
                  title: 'Space between',
                  className: 'icons-flex icon-just-sp-bet',
                }, {
                  value: 'space-around',
                  title: 'Space around',
                  className: 'icons-flex icon-just-sp-ar',
                }, {
                  value: 'center',
                  title: 'Center',
                  className: 'icons-flex icon-just-sp-cent',
                }],
              }, {
                name: 'Align',
                property: 'align-items',
                type: 'radio',
                defaults: 'center',
                list: [{
                  value: 'flex-start',
                  title: 'Start',
                  className: 'icons-flex icon-al-start',
                }, {
                  value: 'flex-end',
                  title: 'End',
                  className: 'icons-flex icon-al-end',
                }, {
                  value: 'stretch',
                  title: 'Stretch',
                  className: 'icons-flex icon-al-str',
                }, {
                  value: 'center',
                  title: 'Center',
                  className: 'icons-flex icon-al-center',
                }],
              }, {
                name: 'Flex Children',
                property: 'label-parent-flex',
                type: 'integer',
              }, {
                name: 'Order',
                property: 'order',
                type: 'integer',
                defaults: 0,
                min: 0,
              }, {
                name: 'Flex',
                property: 'flex',
                type: 'composite',
                properties: [{
                  name: 'Grow',
                  property: 'flex-grow',
                  type: 'integer',
                  defaults: 0,
                  min: 0,
                }, {
                  name: 'Shrink',
                  property: 'flex-shrink',
                  type: 'integer',
                  defaults: 0,
                  min: 0,
                }, {
                  name: 'Basis',
                  property: 'flex-basis',
                  type: 'integer',
                  units: ['px', '%', ''],
                  unit: '',
                  defaults: 'auto',
                }],
              }, {
                name: 'Align',
                property: 'align-self',
                type: 'radio',
                defaults: 'auto',
                list: [{
                  value: 'auto',
                  name: 'Auto',
                }, {
                  value: 'flex-start',
                  title: 'Start',
                  className: 'icons-flex icon-al-start',
                }, {
                  value: 'flex-end',
                  title: 'End',
                  className: 'icons-flex icon-al-end',
                }, {
                  value: 'stretch',
                  title: 'Stretch',
                  className: 'icons-flex icon-al-str',
                }, {
                  value: 'center',
                  title: 'Center',
                  className: 'icons-flex icon-al-center',
                }],
              }],
            },
            ],
          },
        },
      });

      editor.I18n.addMessages({
        en: {
          styleManager: {
            properties: {
              'background-repeat': 'Repeat',
              'background-position': 'Position',
              'background-attachment': 'Attachment',
              'background-size': 'Size',
            },
          },
        },
      });

      const panels = editor.Panels;

      editor.setStyle(contents.styles);
      editor.setComponents(contents.components);

      editor.on('load', () => {
        const { $ } = grapesjs;

        // Load and show settings and style manager
        const openTmBtn = panels.getButton('views', 'open-tm');
        if (openTmBtn) {
          openTmBtn.set('active', 1);
        }
        const openSm = panels.getButton('views', 'open-sm');
        if (openSm) {
          openSm.set('active', 1);
        }

        // Add Settings Sector
        const traitsSector = $(`<div class="gjs-sm-sector no-select">
          <div class="gjs-sm-title"><span class="icon-settings fa fa-cog"></span> Settings</div>
          <div class="gjs-sm-properties" style="display: none;"></div></div>`);
        const traitsProps = traitsSector.find('.gjs-sm-properties');
        traitsProps.append($('.gjs-trt-traits'));
        $('.gjs-sm-sectors').before(traitsSector);
        traitsSector.find('.gjs-sm-title').on('click', () => {
          const traitStyle = traitsProps.get(0).style;
          const hidden = traitStyle.display === 'none';
          if (hidden) {
            traitStyle.display = 'block';
          } else {
            traitStyle.display = 'none';
          }
        });

        // Open block manager
        const openBlocksBtn = panels.getButton('views', 'open-blocks');
        if (openBlocksBtn) {
          openBlocksBtn.set('active', 1);
        }

        // Set Tooltips
        [['sw-visibility', 'Show Borders'], ['preview', 'Preview'], ['fullscreen', 'Fullscreen'],
          ['export-template', 'Export'], ['undo', 'Undo'], ['redo', 'Redo'],
          ['gjs-open-import-webpage', 'Import'], ['canvas-clear', 'Clear canvas']]
          .forEach((item) => {
            panels.getButton('options', item[0]).set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom' });
          });
        [['open-sm', 'Style Manager'], ['open-layers', 'Layers'], ['open-blocks', 'Blocks']]
          .forEach((item) => {
            panels.getButton('views', item[0]).set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom' });
          });

        // Set View Components as Active
        const viewComponentsBtn = panels.getButton('options', 'sw-visibility');
        if (viewComponentsBtn) {
          viewComponentsBtn.set('active', 1);
        }
      });

      // const assetManager = editor.AssetManager;

      editor.on('update', () => {
        updateCnt++;
        if (updateCnt > 2) {
          setHasUnsavedData(true);
        }
      });

      editor.on('asset:upload:start', () => {
        startLoading();
      });

      editor.on('asset:upload:end', () => {
        finishLoading();
      });

      editor.on('asset:upload:error', (err) => {
        createToast({
          type: 'error',
          message: err.message || 'Uploading image has been failed.',
        });
        finishLoading();
      });

      editor.on('asset:remove', (e) => {
        const filePath = e.attributes.path;
        if (filePath) {
          PortfolioService.removeCaseStudyImage(filePath).then();
        }
      });

      onEditorInit(editor);
    }
  });

  if (!caseStudy) {
    return null;
  }

  return (
    contents
      ? <div id="gjs" />
      : <div className="w-100 text-center pt-5">Loading...</div>
  );
};

GrapesJsEditor.propTypes = {
  caseStudy: PropTypes.object,
  project: PropTypes.object,
  onEditorInit: PropTypes.func,
  setHasUnsavedData: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

GrapesJsEditor.defaultProps = {
  caseStudy: null,
  project: null,
  onEditorInit: null,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setHasUnsavedData: PortfolioAction.setHasUnsavedData,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(GrapesJsEditor);
