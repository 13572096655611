import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Button } from '@material-ui/core';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as AuthService from '../../../services/auth.service';
import * as AuthAction from '../../../store/actions/auth.action';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import Validators from '../../common/FormControl/validators';
import { patchFormData, validateFormGroup } from '../../common/FormControl/utils';

const ChangeProfileModal = ({
  open, user, onClose, setUser, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    name: {
      name: 'name',
      label: 'Name',
      validators: [Validators.required()],
    },
    email: {
      name: 'email',
      label: 'Email',
      disabled: true,
    },
  }), []);

  const [formData, setFormData] = useState({
    name: '',
  });

  useEffect(() => {
    if (!user)
      return;

    setFormData({
      name: user.name || '',
    });

    patchFormData(form, {
      name: user.name || '',
      email: user.email || '',
    });
  }, [form, user]);

  const onInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSubmit = async () => {
    if (!validateFormGroup(form)) {
      return;
    }

    startLoading();
    const result = await AuthService.updateProfile(formData);
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Updating profile has been failed',
      });
      return;
    }

    setUser(result.data.user);
    createToast({
      type: 'success',
      message: 'Profile has been updated successfully!',
    });
    onClose();
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog
      title="Profile"
      className="update-profile-modal"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" onClick={onSubmit}>Save</Button>
        </>
      )}
    >
      <FormControl control={form.name} size="sm" onChange={onInputChange} />
      <FormControl control={form.email} size="sm" className="mb-0" />
    </Dialog>
  );
};

ChangeProfileModal.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ChangeProfileModal.defaultProps = {
  open: false,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser: AuthAction.setUser,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ChangeProfileModal);
