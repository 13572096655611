import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.scss';

import { Button, Grid } from '@material-ui/core';
import ImagesGridView from '../ImagesGridView';
import Dialog from '../../common/Dialog';
import FormControl from '../../common/FormControl';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import Validators from '../../common/FormControl/validators';
import { patchFormData, setFormControlOptions, validateFormGroup } from '../../common/FormControl/utils';

const MassMoveModal = ({
  open, images, onClose, clients, client, activeProject,
  massMoveImages, startLoading, finishLoading, createToast,
}) => {
  const form = useMemo(() => ({
    currentClient: {
      name: 'currentClient',
      label: 'Current Client',
      disabled: true,
    },
    currentProject: {
      name: 'currentProject',
      label: 'Current Project',
      disabled: true,
    },
    targetClient: {
      name: 'targetClient',
      type: 'autocomplete',
      label: 'Select New Client',
      options: [],
      labelField: 'name',
      validators: [Validators.required()],
    },
    targetProject: {
      name: 'targetProject',
      type: 'autocomplete',
      label: 'Select New Project',
      options: [],
      labelField: 'name',
      validators: [Validators.required()],
    },
  }), []);

  const [editingImages, setEditingImages] = useState(images);

  useEffect(() => {
    setEditingImages(images);
  }, [images]);

  const onClientSelect = () => {
    let availableProjects = [];
    const targetClient = form.targetClient.value;
    if (targetClient) {
      availableProjects = targetClient.projects;
      if (targetClient === client)
        availableProjects = availableProjects.filter((p) => p._id !== activeProject._id);
    }
    setFormControlOptions(form.targetProject, {
      options: availableProjects,
      value: null,
    });
  };

  useEffect(() => {
    patchFormData(form, {
      currentClient: client.name,
      currentProject: activeProject.name,
      targetClient: client,
    });
    onClientSelect();
  }, [activeProject.name, client, form]);

  useEffect(() => {
    setFormControlOptions(form.targetClient, {
      options: clients,
    });
  }, [clients, form.targetClient]);

  const onRemoveMedia = (imageId) => {
    const filteredImages = editingImages.filter((image) => image._id !== imageId);
    setEditingImages(filteredImages);
    if (!filteredImages.length) {
      onClose();
    }
  };

  const onSave = async () => {
    if (!validateFormGroup(form))
      return;

    const targetClient = form.targetClient.value;
    const targetProject = form.targetProject.value;

    startLoading();
    const result = await PortfolioService.massMoveImages(
      editingImages.map((image) => image._id),
      targetProject._id,
    );
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        title: 'Failed!',
        message: result.message || 'Move media has been failed.',
      });
    } else {
      const movedImages = [];
      editingImages.forEach((image) => {
        if (result.data.imageIds.indexOf(image._id) !== -1) {
          movedImages.push({
            ...image,
            ...result.data.changes,
          });
        }
      });
      massMoveImages(movedImages, targetClient, targetProject);
      createToast({
        type: 'success',
        message: `${movedImages.length} ${movedImages.length === 1 ? 'media has' : 'medias have'} been moved successfully.`,
      });
      onClose();
    }
  };

  return (
    <Dialog
      title="Move Media"
      className="mass-move-modal"
      maxWidth="md"
      open={open}
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" onClick={onSave}>Save</Button>
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImagesGridView images={editingImages} onRemoveMedia={onRemoveMedia} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.currentClient} size="sm" className="mb-0" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.currentProject} size="sm" className="mb-0" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.targetClient} size="sm" className="mb-0" onChange={onClientSelect} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl control={form.targetProject} size="sm" className="mb-0" />
        </Grid>
      </Grid>
    </Dialog>
  );
};

MassMoveModal.propTypes = {
  open: PropTypes.bool,
  images: PropTypes.array,
  clients: PropTypes.array,
  client: PropTypes.object,
  activeProject: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  massMoveImages: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

MassMoveModal.defaultProps = {
  open: false,
  images: [],
  clients: [],
  client: null,
  onClose: null,
};

const mapStateToProps = (store) => ({
  clients: store.portfolioReducer.clients,
  client: store.portfolioReducer.client,
  activeProject: store.portfolioReducer.activeProject,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      massMoveImages: PortfolioAction.massMoveImages,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MassMoveModal);
