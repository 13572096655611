import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router';
import './style.scss';

import { Button, Tooltip } from '@material-ui/core';
import { Clear, FilterCenterFocus, WidgetsOutlined } from '@material-ui/icons';
import UploadModal from '../../UploadModal';
import CaptureModal from '../../CaptureModal';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import ROUTES from '../../../../constants/routes.json';
import ExportButton from './ExportButton';
import { ROLES } from '../../../../constants';

const GalleryHeader = ({
  user, client, project, activeTab, selectedImages, setSelectedImages,
}) => {
  const [uploadImages, setUploadImages] = useState(null);
  const [showCaptureModal, setShowCaptureModal] = useState(false);

  const history = useHistory();

  const onResetSelectedImages = () => {
    setSelectedImages([]);
  };

  const onCapture = () => {
    setShowCaptureModal(true);
  };

  const onUploadCapturedImages = (images) => {
    setUploadImages(images);
    setShowCaptureModal(false);
  };

  const onViewProjectScrum = () => {
    history.push(`${ROUTES.PROJECT_SCRUM.url}?client=${client._id}&project=${project._id}`);
  };

  return (
    <>
      <div className="header mb-4">
        <div className="w-0 flex-grow-1 text-ellipsis mr-3">
          <Tooltip title={project.name} placement="top" arrow>
            <h2 className="d-inline text-ellipsis">{project.name}</h2>
          </Tooltip>
        </div>
        {
          activeTab === 'screenshot' && (
            <div className="d-flex flex-wrap align-items-center">
              <Button
                className="btn-primary size-sm px-3"
                onClick={onResetSelectedImages}
                disabled={!selectedImages.length}
              >
                <Clear className="text-sm mr-2" />
                Reset all
              </Button>

              {user.role !== ROLES.CLIENT && (
                <Button
                  className="btn-primary size-sm px-3 ml-2"
                  onClick={onCapture}
                >
                  <FilterCenterFocus className="text-lg mr-2" />
                  Capture
                </Button>
              )}

              <ExportButton medias={selectedImages} />

              {user.role !== ROLES.CLIENT && (
                <Button
                  className="btn-primary size-sm px-3 ml-2"
                  disabled={!project}
                  onClick={() => setUploadImages([])}
                >
                  <img src="/resources/images/svgs/upload-white.svg" className="mr-2" alt="" />
                  Upload
                </Button>
              )}
            </div>
          )
        }
        {
          activeTab === 'progress' && (
            <Button
              className="btn-primary size-sm px-3 ml-2"
              onClick={onViewProjectScrum}
            >
              <WidgetsOutlined className="text-lg mr-2" />
              Project Scrum
            </Button>
          )
        }
      </div>

      {Boolean(uploadImages) && (
        <UploadModal open capturedImages={uploadImages} activeProject={project} onClose={() => setUploadImages(null)} />
      )}
      {showCaptureModal && (
        <CaptureModal open onCancel={() => setShowCaptureModal(false)} onUpload={onUploadCapturedImages} />
      )}
    </>
  );
};

GalleryHeader.propTypes = {
  user: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
  selectedImages: PropTypes.array,
  setSelectedImages: PropTypes.func.isRequired,
};

GalleryHeader.defaultProps = {
  activeTab: 'screenshot',
  selectedImages: [],
};

const mapStateToProps = (store) => ({
  client: store.portfolioReducer.client,
  activeTab: store.portfolioReducer.activeTab,
  selectedImages: store.portfolioReducer.selectedImages,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setSelectedImages: PortfolioAction.setSelectedImages,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(GalleryHeader);
