const mobileDevices = [
  {
    name: 'iPhone 5S',
    width: 822,
    height: 1683,
    offsetX: 86,
    offsetY: 243,
    screenWidth: 660,
    screenHeight: 1156,
    image: new Image(),
  },
  {
    name: 'iPhone 7',
    width: 481,
    height: 940,
    offsetX: 40,
    offsetY: 114,
    screenWidth: 403,
    screenHeight: 712,
    image: new Image(),
  },
  {
    name: 'iPhone 8',
    width: 552,
    height: 1107,
    offsetX: 36,
    offsetY: 125,
    screenWidth: 482,
    screenHeight: 856,
    image: new Image(),
  },
  {
    name: 'iPhone X',
    width: 800,
    height: 1613,
    offsetX: 55,
    offsetY: 43,
    screenWidth: 692,
    screenHeight: 1485,
    image: new Image(),
  },
  {
    name: 'iPad',
    width: 1777,
    height: 2274,
    offsetX: 187,
    offsetY: 207,
    screenWidth: 1399,
    screenHeight: 1864,
    image: new Image(),
  },
  {
    name: 'Samsung-Galaxy-S4',
    width: 1184,
    height: 2343,
    offsetX: 51,
    offsetY: 193,
    screenWidth: 1082,
    screenHeight: 1922,
    image: new Image(),
  },
  {
    name: 'Samsung-Galaxy-S10',
    width: 800,
    height: 1500,
    offsetX: 64,
    offsetY: 29,
    screenWidth: 672,
    screenHeight: 1425,
    borderRadius: 18,
    image: new Image(),
  },
  {
    name: 'MacBook',
    width: 974,
    height: 554,
    offsetX: 109,
    offsetY: 22,
    screenWidth: 757,
    screenHeight: 478,
    image: new Image(),
  },
];

mobileDevices.forEach((device) => {
  const { image } = device;
  image.src = `/resources/images/phone templates/${device.name}.png`;
});

export default mobileDevices;
