import grapesjs from 'grapesjs';
import loadAssets from './assets';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-video', (editor) => {
  loadAssets(editor);
  // loadBlocks(editor);
  loadComponents(editor);
});
