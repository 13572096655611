import React from 'react';
import PropTypes from 'prop-types';
import './PreviewReportModal.scss';

import { Button } from '@material-ui/core';
import Dialog from '../../../common/Dialog';
import config from '../../../../constants/config';
import { convertToRichText, getCurrentWeek } from '../../../../utils';

const PreviewReportModal = ({
  open, report, onClose,
}) => (
  <Dialog
    title="Preview Report"
    className="preview-weekly-report-modal"
    open={open}
    maxWidth="md"
    onClose={onClose}
    footerActions={(
      <Button className="size-sm px-3" onClick={onClose}>Close</Button>
    )}
  >
    <table className="table table-striped table-bordered mt-3">
      <tbody>
        <tr>
          <th>Report Date</th>
          <td>{getCurrentWeek(report.createdAt)}</td>
        </tr>
        <tr>
          <th>Last Edited By</th>
          <td>{report.user.name}</td>
        </tr>
        <tr>
          <th>What did you accomplish this week?</th>
          <td dangerouslySetInnerHTML={{ __html: convertToRichText(report.accomplish) }} />
        </tr>
        <tr>
          <th>What are your plans for next week?</th>
          <td dangerouslySetInnerHTML={{ __html: convertToRichText(report.plan) }} />
        </tr>
        <tr>
          <th>What are your blockers?</th>
          <td dangerouslySetInnerHTML={{ __html: convertToRichText(report.blockers) }} />
        </tr>
        <tr>
          <th>Action items</th>
          <td dangerouslySetInnerHTML={{ __html: convertToRichText(report.actions) }} />
        </tr>
        <tr>
          <th>Status</th>
          <td>{report.status}</td>
        </tr>
        <tr>
          <th>Color</th>
          <td>{report.color}</td>
        </tr>
        <tr>
          <th>Audio</th>
          <td>
            {report.audio && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <audio className="image" src={`${config.REPORT_URL}/${report.audio}?q=${report.updatedAt}`} controls />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  </Dialog>
);

PreviewReportModal.propTypes = {
  open: PropTypes.bool,
  report: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

PreviewReportModal.defaultProps = {
  open: false,
  report: null,
};

export default PreviewReportModal;
