import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { Button, Menu, MenuItem } from '@material-ui/core';
import ExportModal from '../../../ExportModal';

const ExportButton = ({
  medias,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportFormat, setExportFormat] = useState(null);

  const imageCnt = useMemo(() => (
    medias.filter((media) => media.type.startsWith('image')).length
  ), [medias]);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onExport = (format) => {
    setExportFormat(format);
    onCloseMenu();
  };

  return (
    <div className="export-button">
      <Button
        className="btn-primary size-sm px-3 ml-2"
        disabled={!medias.length}
        onClick={onOpenMenu}
      >
        <img src="/resources/images/svgs/export-white.svg" className="mr-2" alt="" />
        Export
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem disabled={!imageCnt} onClick={() => onExport('PDF')}>
          {`PDF${imageCnt ? ` (${imageCnt})` : ''}`}
        </MenuItem>
        <MenuItem disabled={!medias.length} onClick={() => onExport('HTML')}>
          {`HTML${medias.length ? ` (${medias.length})` : ''}`}
        </MenuItem>
        <MenuItem disabled={!medias.length} onClick={() => onExport('PPT')}>
          {`PPT${medias.length ? ` (${medias.length})` : ''}`}
        </MenuItem>
      </Menu>

      {
        exportFormat && (
          <ExportModal show format={exportFormat} onCancel={() => setExportFormat(null)} />
        )
      }
    </div>
  );
};

ExportButton.propTypes = {
  medias: PropTypes.array,
};

ExportButton.defaultProps = {
  medias: [],
};

export default ExportButton;
