import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Redirect } from 'react-router';
import * as AuthAction from '../../store/actions/auth.action';
import * as AuthService from '../../services/auth.service';
import * as CommonAction from '../../store/actions/common.action';
import * as ToastAction from '../../store/actions/toast.action';
import ROUTES from '../../constants/routes.json';

class ActivateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activated: false,
      redirectTo: null,
    };

    this.onLogIn = this.onLogIn.bind(this);
    this.onActivate = this.onActivate.bind(this);
  }

  componentDidMount() {
    let query = {};
    document.location.search.substr(1).split('&').forEach((q) => {
      const words = q.split('=');
      if (words.length === 2) {
        query = {
          ...query,
          [words[0]]: words[1],
        };
      }
    });

    this.token = query.token;

    this.props.startLoading();
    AuthService.canActivateAccount(query.token).then((result) => {
      this.props.finishLoading();
      if (result.error) {
        this.props.createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Verifying token has been failed.',
        });
        this.setState({
          redirectTo: ROUTES.SIGN_IN.url,
        });
      } else {
        this.email = result.data.email;
      }
    });
  }

  onActivate() {
    this.props.startLoading();
    AuthService.activateAccount(this.token).then((result) => {
      this.props.finishLoading();
      if (result.error) {
        this.props.createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Account activation has been failed.',
        });
      } else {
        this.setState({
          activated: true,
        });
        this.token = result.data.token;
        this.user = result.data.user;
      }
    });
  }

  onLogIn() {
    AuthService.setToken(this.token);
    this.props.signIn(this.user);
    this.setState({
      redirectTo: ROUTES.PROJECTS.url,
    });
  }

  render() {
    return (
      <div className="activate-account wrapper-page">
        {
          this.state.redirectTo
            ? <Redirect to={this.state.redirectTo} />
            : null
        }
        <div className="text-center">
          <h1 className="logo">Protovate Portfolio Tool</h1>
        </div>
        <div className="card-box">
          <div className="text-center">
            <h4 className="text-uppercase font-bold m-b-0">Activate Account</h4>
          </div>
          {
            this.state.activated
              ? (
                <div className="panel-body">
                  <div className="form-group text-center mt-3">
                    <b>Congratulations!</b>
                    &nbsp;You have successfully activated your account.
                  </div>

                  <div className="form-group text-center mt-3">
                    <div className="col-xs-12">
                      <button className="btn btn-custom btn-bordered btn-block" onClick={this.onLogIn}>
                        START EXPLORING THE PORTAL NOW!
                      </button>
                    </div>
                  </div>
                </div>
              )
              : (
                <div className="panel-body">
                  <div className="form-group text-center mt-3">
                    Please click the button below to activate account.
                  </div>

                  <div className="form-group text-center mt-3">
                    <div className="col-xs-12">
                      <button className="btn btn-custom btn-bordered btn-block" onClick={this.onActivate}>
                        ACTIVATE ACCOUNT
                      </button>
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

ActivateAccount.propTypes = {
  signIn: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signIn: AuthAction.signIn,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ActivateAccount);
