import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  AccountCircleOutlined,
  ExitToAppOutlined,
  LockOutlined,
} from '@material-ui/icons';

import ChangePasswordModal from '../../Auth/ChangePasswordModal';
import ChangeProfileModal from '../../Auth/ChangeProfileModal';
import * as AuthService from '../../../services/auth.service';
import { signOut } from '../../../store/actions/auth.action';

const ProfileMenu = ({
  user, canNavigate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);

  const dispatch = useDispatch();

  const shortName = useMemo(() => {
    const words = user.name.split(/\s+/);
    const firstName = words[0] || '';
    const lastName = words[1] || '';
    return `${firstName[0] || ''}${lastName[0] || firstName[1] || ''}`.toUpperCase();
  }, [user.name]);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const openProfileModal = () => {
    setShowUpdateProfile(true);
    onCloseMenu();
  };

  const openChangePasswordModal = () => {
    setShowChangePassword(true);
    onCloseMenu();
  };

  const onSignOut = () => {
    if (canNavigate()) {
      AuthService.signOut();
      dispatch(signOut());
    }
    onCloseMenu();
  };

  return (
    <div className="profile-menu">
      <IconButton onClick={onOpenMenu}>{shortName}</IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openProfileModal}>
          <AccountCircleOutlined className="mr-2" />
          Profile
        </MenuItem>
        <MenuItem onClick={openChangePasswordModal}>
          <LockOutlined className="mr-2" />
          Change Password
        </MenuItem>
        <MenuItem onClick={onSignOut}>
          <ExitToAppOutlined className="mr-2" />
          Log Out
        </MenuItem>
      </Menu>

      {
        showUpdateProfile
          ? <ChangeProfileModal user={user} open onClose={() => setShowUpdateProfile(false)} />
          : null
      }
      {
        showChangePassword
          ? <ChangePasswordModal open onClose={() => setShowChangePassword(false)} />
          : null
      }
    </div>
  );
};

ProfileMenu.propTypes = {
  user: PropTypes.object.isRequired,
  canNavigate: PropTypes.func.isRequired,
};

export default ProfileMenu;
