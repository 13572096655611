import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import Dialog from '../../common/Dialog';
import EditUserModal from '../EditUserModal';
import * as AuthService from '../../../services/auth.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';

const UsersModal = ({
  open, onClose, createToast, startLoading, finishLoading,
}) => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    startLoading();
    AuthService.getUsers().then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: result.message || 'Loading users has been failed',
        });
      } else {
        setUsers(result.users);
      }
    });
  }, [createToast, finishLoading, startLoading]);

  const onSaveUser = (updatedUser) => {
    if (editingUser._id) {
      setUsers(users.map((user) => (user._id === editingUser._id ? updatedUser : user)));
    } else {
      setUsers([...users, updatedUser]);
    }
    setEditingUser(null);
  };

  const onDeleteUser = async (user) => {
    if (!window.confirm('Are you sure you want to remove this user?')) {
      return;
    }

    startLoading();
    const result = await AuthService.deleteUser(user);
    finishLoading();

    if (result.error) {
      createToast({
        type: 'error',
        message: result.message || 'Removing user has been failed',
      });
      return;
    }

    createToast({
      type: 'success',
      message: 'User has been removed successfully',
    });

    setUsers(users.filter((u) => u._id !== user._id));
  };

  return (
    <Dialog
      open={open}
      title={`Users (${users.length})`}
      className="users-modal"
      maxWidth="md"
      onClose={onClose}
      footerActions={(
        <>
          <Button className="size-sm px-3 mr-2" onClick={onClose}>Cancel</Button>
          <Button className="btn-primary size-sm px-3" onClick={() => setEditingUser({})}>Add User</Button>
        </>
      )}
    >
      <div className="table-wrapper">
        <table className="users-table w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Allow Report</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              users
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((user) => (
                  <tr key={user._id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td className="text-capitalize">{user.role}</td>
                    <td>
                      {user.allowReport && (
                        <i className="fa fa-check text-success" />
                      )}
                    </td>
                    <td>
                      <Tooltip title="Edit User" placement="top" arrow>
                        <IconButton
                          className="btn-outline-secondary size-sm ml-2"
                          onClick={() => setEditingUser(user)}
                        >
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove User" placement="top" arrow>
                        <IconButton
                          className="btn-outline-secondary size-sm ml-2"
                          onClick={() => onDeleteUser(user)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>

      {editingUser && (
        <EditUserModal
          open
          user={editingUser}
          onSave={onSaveUser}
          onClose={() => setEditingUser(null)}
        />
      )}
    </Dialog>
  );
};

UsersModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

UsersModal.defaultProps = {
  open: false,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(UsersModal);
