import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './GlobalSearchResult.scss';

import { Checkbox } from '@material-ui/core';
import ProjectImage from './ProjectImage';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';

const GlobalSearchResult = ({
  images, selectedImages, setSelectedImages,
}) => {
  const projects = useMemo(() => {
    const searchedProjects = [];
    images.forEach((image) => {
      if (typeof image.project !== 'object') {
        return;
      }
      const project = searchedProjects.find((p) => p._id === image.project._id);
      if (!project) {
        searchedProjects.push({
          ...image.project,
          images: [image],
        });
      } else {
        project.images.push(image);
      }
    });
    return searchedProjects;
  }, [images]);

  const selectedAll = useMemo(() => {
    const selected = [];
    projects.forEach((project) => {
      const unselectedImage = project.images.find((image) => (
        !selectedImages.find((img) => img._id === image._id)
      ));
      selected.push(!unselectedImage);
    });
    return selected;
  }, [projects, selectedImages]);

  const onSelectAll = (project, checked) => {
    let newSelectedImages = selectedImages.filter((img) => img.project._id !== project._id);
    if (checked) {
      newSelectedImages = [
        ...newSelectedImages,
        ...project.images,
      ];
    }
    setSelectedImages(newSelectedImages);
  };

  return (
    <div className="w-100">
      {
        projects.map((project, index) => (
          <div className="project-view" key={project._id}>
            <div className="d-flex align-items-center justify-content-between">
              <h2>{project.name}</h2>
              <div className="d-inline-flex align-items-center">
                <Checkbox
                  id={`select-all-${project._id}`}
                  color="primary"
                  checked={selectedAll[index]}
                  onChange={(e, checked) => onSelectAll(project, checked)}
                />
                <label className="m-0" htmlFor={`select-all-${project._id}`}>Select/Deselect All</label>
              </div>
            </div>
            <div className="image-list">
              {
                project.images.map((image) => <ProjectImage key={image._id} image={image} />)
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

GlobalSearchResult.propTypes = {
  images: PropTypes.array,
  selectedImages: PropTypes.array,
  setSelectedImages: PropTypes.func.isRequired,
};

GlobalSearchResult.defaultProps = {
  images: [],
  selectedImages: [],
};

const mapStateToProps = (store) => ({
  selectedImages: store.portfolioReducer.selectedImages,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      setSelectedImages: PortfolioAction.setSelectedImages,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchResult);
