import ACTION from '../actionTypes';

const initialState = {
  loadingCnt: 0,
  uploading: false,
  progress: 0,
  uploadMessage: '',
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.START_LOADING:
      return {
        ...state,
        loadingCnt: state.loadingCnt + 1,
      };

    case ACTION.FINISH_LOADING:
      return {
        ...state,
        loadingCnt: Math.max(state.loadingCnt - 1, 0),
      };

    case ACTION.FINISH_ALL_LOADING:
      return {
        ...state,
        loadingCnt: 0,
      };

    case ACTION.START_UPLOADING:
      return {
        ...state,
        uploading: true,
        progress: action.progress || 0,
        uploadMessage: action.uploadMessage,
      };
    case ACTION.FINISH_UPLOADING:
      return {
        ...state,
        uploading: false,
        uploadMessage: '',
      };
    case ACTION.SET_UPLOADING_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };

    default:
      return state;
  }
}
