import moment from 'moment';
import { getHtmlTextLength } from '../../../utils';

const Validators = {
  required: (field = 'This field') => ({ value }) => {
    if (value === undefined || value === null || value === '' || (Array.isArray(value) && !value.length))
      return `${field} is required.`;
    return null;
  },
  email: (field = 'This field') => ({ value }) => {
    if (!/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(value))
      return `${field} should be valid email.`;
    return null;
  },
  url: (field = 'This field') => ({ value }) => {
    if (!value)
      return null;
    let domain = value;
    let match = /^https?:\/\/(.*)$/gi.exec(domain);
    if (match) {
      domain = match[1] || '';
    }
    match = /^www\.(.*)$/gi.exec(domain);
    if (match) {
      domain = match[1] || '';
    }
    if (!/^\w+(\.\w+)+$/gi.test(domain))
      return `${field} should be valid url.`;
    return null;
  },
  pattern: (regexp, field = 'This field') => ({ value }) => {
    if (value && !regexp.test(value))
      return `${field} should be valid format.`;
    return null;
  },
  pastTime: (compareTime = null, message = null, field = 'This field') => ({ value }) => {
    if (!compareTime)
      compareTime = new Date();
    if (!message)
      message = 'should be past datetime.';
    if (value && !moment(value).isBefore(moment(compareTime)))
      return `${field} ${message}`;
    return null;
  },
  maxLength: (maxLen, html = false, field = 'This field') => ({ value }) => {
    if (!value)
      return;
    const textLen = html ? getHtmlTextLength(value) : value.length;
    if (textLen > maxLen)
      return `${field} should be at most ${maxLen} characters.`;
    return null;
  },
};

export default Validators;
