import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import './style.scss';

import { Button } from '@material-ui/core';
import FormControl from '../../components/common/FormControl';
import ROUTES from '../../constants/routes.json';
import { parseQuery } from '../../utils';
import * as AuthService from '../../services/auth.service';
import Validators from '../../components/common/FormControl/validators';
import { getFormData, validateFormGroup } from '../../components/common/FormControl/utils';
import { createToast } from '../../store/actions/toast.action';
import { finishLoading, startLoading } from '../../store/actions/common.action';

const ResetPassword = ({ location }) => {
  const form = useMemo(() => ({
    password: {
      name: 'password',
      type: 'password',
      label: 'New Password',
      validators: [Validators.required()],
    },
    confirmPassword: {
      name: 'confirmPassword',
      type: 'password',
      label: 'Password Confirm',
      validators: [Validators.required()],
    },
  }), []);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [canResetPassword, setCanResetPassword] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const queries = parseQuery(location.search);
    setToken(queries.token || '');
    setEmail(queries.email || '');

    dispatch(startLoading());
    AuthService.canResetPassword(queries.token).then((result) => {
      dispatch(finishLoading());
      if (result.error) {
        dispatch(createToast({
          type: 'error',
          message: result.message || 'Token is invalid',
        }));
        setCanResetPassword(false);
      } else {
        setEmail(result.data.email);
        setCanResetPassword(true);
      }
    });
  }, [dispatch, location.search]);

  const onResendEmail = useCallback(() => {
    dispatch(startLoading());
    AuthService.forgotPassword(email).then((result) => {
      dispatch(finishLoading());
      if (result.error) {
        dispatch(createToast({
          type: 'error',
          message: result.message || 'Resending email has been failed',
        }));
      } else {
        history.push(ROUTES.CONFIRM_EMAIL.url, {
          email,
        });
      }
    });
  }, [dispatch, email, history]);

  const validateForm = useCallback(() => {
    if (!validateFormGroup(form))
      return false;

    if (form.password.value !== form.confirmPassword.value) {
      form.confirmPassword.setError('Confirm Password is not match.');
      return false;
    }
    return true;
  }, [form]);

  const onResetPassword = useCallback((e) => {
    e.preventDefault();

    if (!validateForm())
      return;

    const { password } = getFormData(form);

    dispatch(startLoading());
    AuthService.resetPassword(token, password).then((result) => {
      dispatch(finishLoading());
      if (result.error) {
        dispatch(createToast({
          type: 'error',
          message: result.message || 'Reset password failed',
        }));
      } else {
        createToast({
          type: 'success',
          message: 'Your password has been reset successfully.',
        });
        history.push(ROUTES.SIGN_IN.url);
      }
    });
  }, [dispatch, form, history, token, validateForm]);

  const pageContent = useMemo(() => {
    if (canResetPassword === undefined) {
      return (
        <p className="text-muted text-center m-b-0 font-13p mt-3">
          Verifying token...
        </p>
      );
    }
    if (canResetPassword === false) {
      return (
        <>
          <div className="alert alert-danger mt-3 text-center">
            Token may be damaged or expired. Please click this button to resend email.
          </div>
          <button className="btn btn-custom btn-bordered btn-block mt-3" onClick={onResendEmail}>Resend Email</button>
        </>
      );
    }
    return (
      <form onSubmit={onResetPassword}>
        <FormControl control={form.password} />
        <FormControl control={form.confirmPassword} />
        <Button type="submit" fullWidth className="btn btn-warning font-weight-bold">Reset Password</Button>
      </form>
    );
  }, [canResetPassword, form, onResendEmail, onResetPassword]);

  return (
    <div className="reset-password">
      <div className="card-box">
        <h1 className="text-primary text-center mb-5">Reset Password</h1>
        {pageContent}
      </div>

      <div className="text-center mt-3">
        Already have account?
        <Link to="/sign-in" className="text-primary ml-2">
          <b>Sign In</b>
        </Link>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ResetPassword;
