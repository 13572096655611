import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './style.scss';

import { IconButton } from '@material-ui/core';
import ProjectFilterModal from '../FilterModal';

const FilterIconButton = ({
  projectFilter,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  return (
    <>
      <IconButton onClick={() => setShowFilterModal(true)}>
        <i className={`fa fa-filter text-base ${projectFilter.isSet ? 'text-success' : 'text-muted'}`} />
      </IconButton>

      {showFilterModal && (
        <ProjectFilterModal
          open
          onClose={() => setShowFilterModal(false)}
        />
      )}
    </>
  );
};

FilterIconButton.propTypes = {
  projectFilter: PropTypes.object.isRequired,
};

const mapStateToProps = (store) => ({
  projectFilter: store.portfolioReducer.projectFilter,
});

export default connect(mapStateToProps)(FilterIconButton);
