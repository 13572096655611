import ACTION from '../actionTypes';

const initialState = {
  toasts: [],
  newId: 1,
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION.CREATE_TOAST:
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: state.newId,
            ...action.toast,
          },
        ],
        newId: state.newId + 1,
      };

    case ACTION.DELETE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((element) => action.toastId !== element.id && element),
      };

    default:
      return state;
  }
}
