import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './style.scss';

import { Button } from '@material-ui/core';
import config from '../../../../constants/config';
import ImageChangeModal from '../../ImageChangeModal';
import * as PortfolioAction from '../../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';
import FormControl from '../../../common/FormControl';
import { patchFormControl } from '../../../common/FormControl/utils';
import { ROLES } from '../../../../constants';

const LogoImageTab = ({
  user, activeProject, editProject, createToast, startLoading, finishLoading,
}) => {
  const form = useMemo(() => ({
    applyAsCompanyLogo: {
      name: 'applyAsCompanyLogo',
      label: 'Apply as company logo',
      type: 'checkbox',
    },
  }), []);
  const [showChangeModal, setShowChangeModal] = useState(false);

  const logoImage = useMemo(() => (
    activeProject && activeProject.logo
      ? activeProject.preview || `${config.PROJECT_URL}/${activeProject.logo}`
      : null
  ), [activeProject]);

  useEffect(() => {
    patchFormControl(form.applyAsCompanyLogo, activeProject ? activeProject.applyAsCompanyLogo : false);
  }, [activeProject, form.applyAsCompanyLogo]);

  const onChangeLogo = async (image) => {
    const isCreate = !activeProject.logo;
    const imageName = `${activeProject._id}.png`;
    startLoading();
    const result = await PortfolioService.setProjectLogo(activeProject, image, imageName);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || `${isCreate ? 'Creating' : 'Changing'} logo has been failed.`,
        type: 'error',
      });
    } else {
      editProject({
        ...activeProject,
        logo: imageName,
        preview: image.preview,
      });
      createToast({
        message: `Logo has been ${isCreate ? 'created' : 'changed'} successfully.`,
        type: 'success',
      });
    }
  };

  const onRemoveLogo = async () => {
    if (!window.confirm('Are you sure you want to remove logo?')) {
      return;
    }

    startLoading();
    const result = await PortfolioService.removeProjectLogo(activeProject);
    finishLoading();
    if (result.error) {
      createToast({
        message: result.message || 'Removing logo has been failed.',
        type: 'error',
      });
    } else {
      editProject({
        ...activeProject,
        logo: null,
        preview: null,
      });
      createToast({
        message: 'Logo has been removed successfully.',
        type: 'success',
      });
    }
  };

  const onApplyAsCompanyLogo = async (_field, checked) => {
    startLoading();
    const result = await PortfolioService.editProject({
      _id: activeProject._id,
      applyAsCompanyLogo: checked,
    });
    finishLoading();
    if (result.error || !result.data.project) {
      createToast({
        message: result.message || 'Logo action failed.',
        type: 'error',
      });
      return;
    }

    createToast({
      message: 'Logo action was successful.',
      type: 'success',
    });
    editProject(result.data.project);
  };

  if (!activeProject) {
    return null;
  }

  return (
    <>
      <div className="text-center">
        {
          logoImage
            ? (
              <>
                {user.role !== ROLES.CLIENT ? (
                  <>
                    <div className="tab-header d-flex align-items-center justify-content-center">
                      <FormControl
                        control={form.applyAsCompanyLogo}
                        size="sm"
                        className="mb-0"
                        fullWidth={false}
                        onChange={onApplyAsCompanyLogo}
                      />
                      <Button
                        className="btn-warning size-sm px-3 ml-5"
                        onClick={() => setShowChangeModal(true)}
                      >
                        Change Logo
                      </Button>
                      <Button
                        className="btn-danger size-sm px-3 ml-3"
                        onClick={onRemoveLogo}
                      >
                        Remove Logo
                      </Button>
                    </div>
                  </>
                ) : (
                  <h5 className="text-left">Logo</h5>
                )}
                <img className="project-logo" src={logoImage} alt="Logo" />
              </>
            )
            : (
              <div className="my-5 py-4">
                {user.role !== ROLES.CLIENT && (
                  <Button className="btn-primary size-sm px-4 mb-4" onClick={() => setShowChangeModal(true)}>
                    Add Logo
                  </Button>
                )}
                <p className="no-data p-0 m-0">{'Logo for this project hasn\'t been uploaded.'}</p>
              </div>
            )
        }
      </div>

      {showChangeModal && (
        <ImageChangeModal
          open={showChangeModal}
          title={logoImage ? 'Change Logo' : 'Add Logo'}
          image={logoImage ? { type: 'image/png', preview: logoImage } : null}
          accept={['image/*']}
          placeholder="Drag and drop image here or click"
          onClose={() => setShowChangeModal(false)}
          onChange={onChangeLogo}
        />
      )}
    </>
  );
};

LogoImageTab.propTypes = {
  user: PropTypes.object.isRequired,
  activeProject: PropTypes.object,
  editProject: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

LogoImageTab.defaultProps = {
  activeProject: null,
};

const mapStateToProps = (store) => ({
  activeProject: store.portfolioReducer.activeProject,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      editProject: PortfolioAction.editProject,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(LogoImageTab);
