import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './style.scss';

import { Button } from '@material-ui/core';
import FormControl from '../../components/common/FormControl';
import Validators from '../../components/common/FormControl/validators';
import { getFormData, validateFormGroup } from '../../components/common/FormControl/utils';
import { finishLoading, startLoading } from '../../store/actions/common.action';
import { createToast } from '../../store/actions/toast.action';
import { signIn } from '../../store/actions/auth.action';
import * as AuthService from '../../services/auth.service';

const SignIn = () => {
  const form = useMemo(() => ({
    email: {
      name: 'email',
      label: 'Email Address',
      validators: [Validators.required(), Validators.email()],
    },
    password: {
      name: 'password',
      type: 'password',
      label: 'Password',
      validators: [Validators.required()],
    },
    rememberMe: {
      name: 'rememberMe',
      type: 'checkbox',
      label: 'Remember me',
      value: false,
    },
  }), []);

  const dispatch = useDispatch();

  const onSignIn = (e) => {
    e.preventDefault();

    if (!validateFormGroup(form))
      return;

    const data = getFormData(form);

    dispatch(startLoading());
    AuthService.signIn(data.email, data.password).then((result) => {
      dispatch(finishLoading());
      if (result.error) {
        dispatch(createToast({
          type: 'error',
          message: result.error || 'Login failed.',
        }));
      } else {
        dispatch(signIn(result.data.user));
      }
    });
  };

  return (
    <div className="sign-in card-box">
      <h1 className="text-primary text-center mb-5">Log in</h1>

      <form onSubmit={onSignIn}>
        <FormControl control={form.email} />
        <div className="position-relative">
          <FormControl control={form.password} />
          <Link to="/forgot-password" className="forgot-password-link text-warning text-13p">Forgot Password?</Link>
        </div>
        <FormControl control={form.rememberMe} />
        <Button type="submit" fullWidth className="btn btn-warning font-weight-bold">Log in</Button>
      </form>
    </div>
  );
};

export default SignIn;
