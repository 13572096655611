import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { withStyles } from '@material-ui/core/styles';
import MaterialDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 3.75),
    background: '#fafafa',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const DialogTitle = withStyles(styles)((props) => {
  const { children, actions, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className="text-lg text-primary font-weight-bold mr-4">{children}</Typography>
      <div className="d-flex align-items-center">
        {actions}
        {onClose ? (
          <IconButton aria-label="close" className="btn-outline-secondary size-sm" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3.75),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 3.75),
  },
}))(MuiDialogActions);

const Dialog = ({
  title, open, headerActions, footerActions, disableBackdrop, hasCloseIcon, lazyRender,
  onClose, children, ...props
}) => {
  if (!open && lazyRender)
    return null;

  return (
    <MaterialDialog open={open} disableBackdropClick={disableBackdrop} onClose={onClose} {...props}>
      <DialogTitle actions={headerActions} onClose={hasCloseIcon ? onClose : null}>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      {footerActions && (
        <DialogActions>
          {footerActions}
        </DialogActions>
      )}
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  headerActions: PropTypes.node,
  footerActions: PropTypes.node,
  disableBackdrop: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  lazyRender: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

Dialog.defaultProps = {
  title: '',
  open: false,
  headerActions: null,
  footerActions: null,
  disableBackdrop: true,
  hasCloseIcon: true,
  lazyRender: true,
  onClose: () => {},
  children: null,
};

export default Dialog;
