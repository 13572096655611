import pptxgen from 'pptxgenjs';
import config from '../constants/config';
import * as PortfolioService from '../services/portfolio.service';

const devices = [
  {
    name: 'iPhone 5S',
    fileName: 'iphone_5s_black.png',
    width: 822,
    height: 1683,
    offsetX: 86,
    offsetY: 243,
    screenWidth: 660,
    screenHeight: 1156,
    image: new Image(),
  },
  {
    name: 'Samsung-Galaxy-S4',
    fileName: 'samsung_galaxy_s4_black.png',
    width: 1184,
    height: 2343,
    offsetX: 51,
    offsetY: 193,
    screenWidth: 1082,
    screenHeight: 1922,
    image: new Image(),
  },
  {
    name: 'MacBook',
    fileName: 'macbook_white.png',
    width: 2072,
    height: 1164,
    offsetX: 250,
    offsetY: 77,
    screenWidth: 1572,
    screenHeight: 985,
    image: new Image(),
  },
];
for (const device of devices) {
  fetch(`${config.ASSETS_URL}/images/${device.fileName}`)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((url) => device.image.src = url);
}

const themes = [
  '049BE7', 'E49C21', '96040F', '673AB7',
  '77A82A', 'F44336', 'E91E63',
  '9C27B0', 'FF5722', '311B92',
  '98549F', '880E4F', '4CAF50', '8BC34A',
  'FF9800', 'DD2C00', '3E2723',
];

const addMedia = async (slide, url, options) => (
  new Promise((resolve) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      let { x, y, w, h, align } = options;
      const width = image.naturalWidth;
      const height = (image.naturalHeight * 133) / 75;

      h = (w * height) / width;
      if (h > options.h) {
        h = options.h;
        w = (h * width) / height;
      }

      align = align || 'top|left';
      if (align.indexOf('center') !== -1)
        x += (options.w - w) / 2;
      else if (align.indexOf('right') !== -1)
        x += (options.w - w);
      if (align.indexOf('middle') !== -1)
        y += (options.h - h) / 2;
      else if (align.indexOf('bottom') !== -1)
        y += (options.h - h);

      slide.addMedia({ x: `${x}%`, y: `${y}%`, w: `${w}%`, h: `${h}%`, path: url });
      resolve();
    };

    image.onerror = () => {
      resolve();
    };
  })
);

const addVideo = async (slide, url, options) => (
  new Promise((resolve) => {
    const videoElement = document.createElement('video');
    videoElement.style = 'display: none';
    videoElement.src = url;
    videoElement.onloadedmetadata = () => {
      let { x, y, w, h } = options;
      const width = videoElement.videoWidth;
      const height = (videoElement.videoHeight * 133) / 75;

      h = (w * height) / width;
      if (h > options.h) {
        h = options.h;
        w = (h * width) / height;
      }

      x += (options.w - w) / 2;
      y += (options.h - h) / 2;

      slide.addMedia({ type: 'video', path: url, x: `${x}%`, y: `${y}%`, w: `${w}%`, h: `${h}%` });
      resolve();
    };

    videoElement.onerror = () => {
      resolve();
    };
  })
);

const getOutputTexts = (node, options) => {
  let texts = [];

  let newOptions = { ...options };
  switch (node.tagName) {
    case 'B':
      newOptions.bold = true;
      break;
    case 'I':
      newOptions.italic = true;
      break;
    case 'U':
      newOptions.underline = true;
      break;
    case 'STRIKE':
      newOptions.strike = true;
      break;
    case 'SUP':
      newOptions.superscript = true;
      break;
    case 'SUB':
      newOptions.subscript = true;
      break;
    case 'BR':
      texts.push({ text: '', options: { breakLine: true } });
      return texts;
    case undefined:
      texts.push({ text: node.textContent.replace(String.fromCharCode(160), ' '), options: newOptions });
      return texts;
    default:
      break;
  }

  if (node.hasAttribute('color')) {
    newOptions.color = node.attributes.color.value.substr(1);
  }

  if (node.style.color) {
    const match = /rgba?\((\d+), (\d+), (\d+)(, \d+)?\)/g.exec(node.style.color);
    if (match) {
      const r = parseInt(match[1], 10).toString(16);
      const g = parseInt(match[2], 10).toString(16);
      const b = parseInt(match[3], 10).toString(16);
      newOptions.color = `${r.length === 1 ? '0' : ''}${r}${g.length === 1 ? '0' : ''}${g}${b.length === 1 ? '0' : ''}${b}`;
    }
  }
  if (node.style.backgroundColor) {
    const match = /rgba?\((\d+), (\d+), (\d+)(, \d+)?\)/g.exec(node.style.backgroundColor);
    if (match) {
      const r = parseInt(match[1], 10).toString(16);
      const g = parseInt(match[2], 10).toString(16);
      const b = parseInt(match[3], 10).toString(16);
      newOptions.fill = { color: `${r.length === 1 ? '0' : ''}${r}${g.length === 1 ? '0' : ''}${g}${b.length === 1 ? '0' : ''}${b}` };
      newOptions.h = 3;
    }
  }
  if (node.style.fontSize) {
    const match = /^\d+/g.exec(node.style.fontSize);
    if (match) {
      newOptions.fontSize = parseInt(match[0], 10);
    }
  }
  if (node.style.fontFamily) {
    newOptions.fontFamily = node.style.fontFamily;
  }
  if (node.style.fontWeight && (node.style.fontWeight.startsWith('bold') || parseInt(node.style.fontWeight, 10) >= 700)) {
    newOptions.bold = true;
  }
  if (node.style.fontStyle === 'italic') {
    newOptions.italic = true;
  }
  if (node.style.textDecorationLine === 'underline') {
    newOptions.underline = true;
  }
  if (node.style.textDecorationLine === 'line-through') {
    newOptions.strike = true;
  }

  switch (node.tagName) {
    case 'UL': {
      newOptions.indentLevel = newOptions.indentLevel === undefined ? 0 : newOptions.indentLevel + 1;
      newOptions.bullet = { indent: 10, characterCode: '2022' };
      for (const li of node.children) {
        texts = texts.concat(getOutputTexts(li, newOptions));
      }
      break;
    }
    case 'OL': {
      newOptions.indentLevel = newOptions.indentLevel === undefined ? 0 : newOptions.indentLevel + 1;
      newOptions.bullet = { indent: 15, type: 'number' };
      for (const li of node.children) {
        texts = texts.concat(getOutputTexts(li, newOptions));
      }
      break;
    }
    default: {
      let el = node.firstChild;
      while (el) {
        texts = texts.concat(getOutputTexts(el, newOptions));
        newOptions = { ...newOptions, bullet: undefined };
        el = el.nextSibling;
      }
    }
  }

  if (/^(DIV|P|H\d|UL|OL)$/gi.test(node.tagName) || node.style.display === 'block') {
    texts.push({ text: '', options: { breakLine: true } });
  }

  return texts;
};

const addRichText = (slide, richText, options, position) => {
  const textElement = document.createElement('div');
  textElement.style.display = 'none';
  textElement.innerHTML = richText;
  const texts = getOutputTexts(textElement, options);
  let minFontSize = 10000000;
  texts.forEach((text) => {
    if (text.options.fontSize && text.options.fontSize < minFontSize)
      minFontSize = text.options.fontSize;
  });
  if (minFontSize > 14) {
    const delta = minFontSize - 14;
    texts.forEach((text) => {
      if (text.options.fontSize)
        text.options.fontSize -= delta;
    });
  }
  slide.addText(texts, position);
};

const getCroppedImage = (image, crop, device) => {
  const width = (image.naturalWidth * crop.width) / 100;
  const height = (image.naturalHeight * crop.height) / 100;
  const offsetX = (image.naturalWidth * crop.x) / 100;
  const offsetY = (image.naturalHeight * crop.y) / 100;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const screenWidth = Math.min(width, device.screenWidth);
  const screenHeight = Math.min(height, device.screenHeight);
  const totalWidth = (screenWidth / device.screenWidth) * device.width;
  const totalHeight = (screenHeight / device.screenHeight) * device.height;
  const screenX = (screenWidth / device.screenWidth) * device.offsetX;
  const screenY = (screenHeight / device.screenHeight) * device.offsetY;

  canvas.width = totalWidth;
  canvas.height = totalHeight;

  context.fillStyle = 'white';
  context.fillRect(screenX, screenY, screenWidth, screenHeight);
  context.drawImage(image, offsetX, offsetY, width, height, screenX, screenY, screenWidth, screenHeight);

  context.drawImage(device.image, 0, 0, totalWidth, totalHeight);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    });
  });
};

const getImagePreview = async (media) => {
  const ext = media.type.split(/\W/)[1];
  const device = devices.find((d) => d.name === media.crop.device);
  if (!device)
    return `${config.PORTFOLIO_URL}/${media._id}.${ext}`;

  const imageUrl = await fetch(`${config.PORTFOLIO_URL}/${media._id}_org.${ext}`)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob));

  return new Promise((resolve) => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      image.onload = null;
      getCroppedImage(image, media.crop, device).then((newImage) => {
        resolve(newImage);
      });
    };
  });
};

const addHeader = async (slide, project) => {
  slide.addMedia({ x: '0%', y: '0%', w: '100%', h: '12%', path: `${config.ASSETS_URL}/images/header.png` });

  let clientLogo = `${config.ASSETS_URL}/images/protovate.png`;
  if (project.logo && project.applyAsCompanyLogo)
    clientLogo = `${config.PROJECT_URL}/${project.logo}`;
  else if (project.client.logo)
    clientLogo = `${config.CLIENT_URL}/${project.client.logo}`;
  await addMedia(slide, clientLogo, { x: 2.3, y: 1.2, w: 32, h: 6.5 });

  await addMedia(slide, `${config.ASSETS_URL}/images/phone.png`, { x: 2.3, y: 8.5, w: 1.8, h: 2.4 });
  let clientPhone = '1 844 202 0124';
  if (project.clientPhone && project.applyAsCompanyPhone)
    clientPhone = project.clientPhone;
  else if (project.client.phone && project.client.applyAsCompanyPhone)
    clientPhone = project.client.phone;
  slide.addText(clientPhone, { x: '3.8%', y: '8.5%', h: '2.4%', fontFace: 'Arial', fontSize: 10, color: 'FFFFFF' });

  await addMedia(slide, `${config.ASSETS_URL}/images/website.png`, { x: 16, y: 8.5, w: 1.8, h: 2.4 });
  let clientWebsite = 'www.protovate.com';
  if (project.clientWebsite && project.applyAsCompanyWebsite)
    clientWebsite = project.clientWebsite;
  else if (project.client.website && project.client.applyAsCompanyWebsite)
    clientWebsite = project.client.website;
  slide.addText(clientWebsite, { x: '17.5%', y: '8.5%', h: '2.4%', fontFace: 'Arial', fontSize: 10, color: 'FFFFFF' });
};

const addFooter = async (slide, sectionName, pageNumber, themeColor) => {
  slide.addMedia({ x: '0%', y: '94%', w: '100%', h: '6%', path: `${config.ASSETS_URL}/images/footer_${themeColor}.png` });

  const r = Math.round(parseInt(themeColor.slice(0, 2), 16) * 0.8).toString(16);
  const g = Math.round(parseInt(themeColor.slice(2, 4), 16) * 0.8).toString(16);
  const b = Math.round(parseInt(themeColor.slice(4, 6), 16) * 0.8).toString(16);
  const bgColor = `${r.length === 1 ? '0' : ''}${r}${g.length === 1 ? '0' : ''}${g}${b.length === 1 ? '0' : ''}${b}`;
  slide.addText(pageNumber, { x: '94%', y: '94%', w: '6%', h: '6%', fontFace: 'Arial', fontSize: 17, color: 'FFFFFF', fill: { color: bgColor }, align: 'center' });

  slide.addText(sectionName.toUpperCase(), { x: '46%', y: '94%', w: '46%', h: '6%', fontFace: 'Arial', fontSize: 14, color: 'FFFFFF', align: 'right', bold: true });
};

const addCoverSlide = async (pptx, project, pageNumber, themeColor) => {
  const slide = pptx.addSlide();
  // slide.background = { path: `${config.ASSETS_URL}/images/background.png` };

  await addHeader(slide, project);

  slide.addText(project.name, { x: '4%', y: '18%', w: '36.5%', h: '12%', fontFace: 'Arial', fontSize: 30, bold: true, color: '0D1258', breakLine: true });
  if (project.logo)
    await addMedia(slide, `${config.PROJECT_URL}/${project.logo}`, { x: 40.5, y: 8, w: 55.5, h: 22 });

  let y = 32;
  for (const link of project.liveLinks) {
    slide.addText(link.name, { x: '4%', y: `${y + 2}%`, w: '92%', h: '3.5%', fontFace: 'Arial', fontSize: 16, color: '2F2F2F' });
    const linkUrl = /^\w+:\/\//.test(link.link) ? link.link : `http://${link.link}`;
    slide.addText(link.link, { x: '4%', y: `${y + 6}%`, w: '92%', h: '3%', fontFace: 'Arial', fontSize: 14, color: '5BA6EF', underline: true, hyperlink: { url: linkUrl } });
    y += 9;
  }

  y += 4;
  if (project.introduction) {
    addRichText(slide, project.introduction, { fontFace: 'Arial', fontSize: 12, color: '4B4C5C', paraSpaceAfter: 3 }, { x: '4%', y: `${y}%`, w: '92%', h: `${89 - y}%`, valign: 'top' });
  }

  await addFooter(slide, 'Introduction', pageNumber, themeColor);
};

const addMediaSlide = async (pptx, media, pageNumber, themeColor) => {
  const slide = pptx.addSlide();
  // slide.background = { path: `${config.ASSETS_URL}/images/background.png` };

  await addHeader(slide, media.project);

  let y = 16;
  if (media.subSection) {
    slide.addText(media.subSection, { x: '4%', y: `${y}%`, w: '32.5%', h: '12%', fontFace: 'Arial', fontSize: 22, bold: true, color: '0D1258', breakLine: true, valign: 'bottom' });
    y += 14;
    slide.addShape(pptx.ShapeType.rect, { x: '5%', y: `${y}%`, w: '8%', h: '0.7%', fill: { color: themeColor } });
    y += 3;
  }

  if (media.description) {
    addRichText(slide, media.description, { fontFace: 'Arial', fontSize: 12, color: '2F2F2F', paraSpaceAfter: 3 }, { x: '4%', y: `${y}%`, w: '32.5%', h: `${93 - y}%`, valign: 'top' });
  }

  const isImage = media.type.startsWith('image');
  const ext = media.type.split(/\W/)[1];
  if (isImage) {
    const imageUrl = await getImagePreview(media);
    await addMedia(slide, imageUrl, { x: 38.5, y: 2.5, w: 59.3, h: 87.5, align: 'center|middle' });
  } else {
    await addVideo(slide, `${config.VIDEO_URL}/${media._id}.${ext}`, { x: 38.5, y: 2.5, w: 59.5, h: 87.5 });
  }

  await addFooter(slide, media.section, pageNumber, themeColor);
};

export const makePPT = async (mediaIds) => {
  const pptx = new pptxgen();
  // pptx.defineLayout({ name:'A3', width:16.5, height:11.7 });
  // pptx.layout = 'A3';
  pptx.layout = 'LAYOUT_WIDE';
  // pptx.layout = 'LAYOUT_4x3';

  let lastProject = null;
  let pageNumber = 1;
  let themeIndex = Math.floor(Math.random() * themes.length);
  const sectionColors = {};

  for (const mediaId of mediaIds) {
    const result = await PortfolioService.getImage(mediaId);
    if (result.error)
      continue;

    const media = result.image;
    if (media.project._id !== lastProject?._id) {
      lastProject = media.project;
      const theme = themes[themeIndex];
      themeIndex = (themeIndex + 1) % themes.length;
      await addCoverSlide(pptx, media.project, pageNumber, theme);
      pageNumber++;
    }

    if (!sectionColors[media.section]) {
      const theme = themes[themeIndex];
      themeIndex = (themeIndex + 1) % themes.length;
      sectionColors[media.section] = theme;
    }

    await addMediaSlide(pptx, media, pageNumber, sectionColors[media.section]);
    pageNumber++;
  }

  return pptx;
};
