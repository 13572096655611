import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './RecentChanges.scss';

import moment from 'moment';
import * as PortfolioService from '../../../../services/portfolio.service';
import * as ToastAction from '../../../../store/actions/toast.action';
import * as CommonAction from '../../../../store/actions/common.action';

const RecentChanges = ({
  project, section, activeImage, onSelectImage, createToast, startLoading, finishLoading,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    startLoading();
    PortfolioService.getRecentChanges(project, section).then((result) => {
      finishLoading();
      if (result.error) {
        createToast({
          type: 'error',
          message: 'Loading recent changes has been failed.',
        });
        return;
      }

      setImages(result.data.images);
    });
  }, [createToast, finishLoading, project, section, startLoading]);

  useEffect(() => {
    if ((!activeImage || !images.find((img) => img._id === activeImage._id)) && images.length) {
      onSelectImage(images[0]);
    }
  }, [activeImage, images, onSelectImage]);

  return (
    <div className="recent-changes w-100">
      <h5 className="mb-4">Recent changes</h5>

      {
        images.length ? (
          <table className="w-100">
            <thead>
              <tr>
                {
                  !section && (
                    <th>Section</th>
                  )
                }
                <th>Sub Section</th>
                <th>Last Modified</th>
              </tr>
            </thead>
            <tbody>
              {
                images.map((image) => (
                  <tr key={image._id} className={`${image?._id === activeImage?._id ? 'active' : ''}`} onClick={() => onSelectImage(image)}>
                    {
                      !section && (
                        <td width="30%">
                          <span title={image.section}>{image.section}</span>
                        </td>
                      )
                    }
                    <td width={section ? '60%' : '40%'}>
                      <span title={image.subSection || image.section}>{image.subSection || image.section}</span>
                    </td>
                    <td width={section ? '40%' : '30%'}>
                      <span>{moment().to(image.lastModified)}</span>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        ) : (
          <p className="text-center m-5">No recent changes</p>
        )
      }
    </div>
  );
};

RecentChanges.propTypes = {
  project: PropTypes.object.isRequired,
  section: PropTypes.string,
  activeImage: PropTypes.object,
  onSelectImage: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

RecentChanges.defaultProps = {
  section: undefined,
  activeImage: null,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(
    {
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  )
);

export default connect(null, mapDispatchToProps)(RecentChanges);
