import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import { Checkbox, IconButton, Tooltip } from '@material-ui/core';
import { AccountBox, DeleteOutline } from '@material-ui/icons';
import * as PortfolioAction from '../../../store/actions/portfolio.action';
import * as PortfolioService from '../../../services/portfolio.service';
import * as ToastAction from '../../../store/actions/toast.action';
import * as CommonAction from '../../../store/actions/common.action';
import config from '../../../constants/config';

const ContactItem = ({
  contact, selected, selectable, removable, hasAction, onSelect, onEdit, onDeselect,
  removeContact, startLoading, finishLoading, createToast,
}) => {
  const imageUrl = useMemo(() => (
    `${config.CONTACT_URL}/${contact.image}?q=${contact.updatedAt}`
  ), [contact]);

  const onRemove = () => {
    if (!window.confirm('Are you sure to remove this contact?')) {
      return;
    }

    if (selected) {
      onSelect();
    }

    startLoading();
    PortfolioService.removeContact(contact._id).then((result) => {
      finishLoading();

      if (result.error) {
        createToast({
          type: 'error',
          title: 'Failed!',
          message: result.message || 'Removing contact has been failed.',
        });
      } else {
        createToast({
          type: 'success',
          message: 'You have removed contact successfully.',
        });
        removeContact(contact._id);
      }
    });
  };

  const onEditContact = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit();
  };

  const onRemoveContact = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove();
  };

  return (
    <div className="contact-item" onClick={onSelect}>
      {selectable && (
        <Checkbox
          color="primary"
          checked={selected}
        />
      )}
      <div className="image">
        {
          contact.image
            ? <img src={imageUrl} alt="" />
            : <AccountBox fontSize="large" />
        }
      </div>
      <div className="contact-name flex-grow-1 text-xl text-ellipsis ml-3">{contact.primary}</div>
      {hasAction && (
        <div className="actions d-flex ml-3">
          <Tooltip title="Edit contact" placement="top" arrow>
            <IconButton className="btn-outline-secondary size-sm" onClick={onEditContact}>
              <img src="/resources/images/svgs/user-edit.svg" alt="Edit" width={14} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove contact" placement="top" arrow>
            <IconButton className="btn-outline-secondary size-sm ml-1" onClick={onRemoveContact}>
              <img src="/resources/images/svgs/user-times.svg" alt="Remove" width={14} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {removable && (
        <Tooltip title="Deselect contact" placement="top" arrow>
          <IconButton className="btn-outline-secondary size-sm ml-1" onClick={onDeselect}>
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

ContactItem.propTypes = {
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  removable: PropTypes.bool,
  hasAction: PropTypes.bool,
  contact: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onDeselect: PropTypes.func,
  removeContact: PropTypes.func.isRequired,
  createToast: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
};

ContactItem.defaultProps = {
  selected: false,
  selectable: false,
  removable: false,
  hasAction: false,
  onSelect: () => {},
  onEdit: () => {},
  onDeselect: () => {},
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeContact: PortfolioAction.removeContact,
      createToast: ToastAction.createToast,
      startLoading: CommonAction.startLoading,
      finishLoading: CommonAction.finishLoading,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ContactItem);
